import { useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";

import facebookIcon from "./images/Facebook.svg"
import facebookIconContrast from "./images/FacebookContrast.svg"
import instagramIcon from "./images/Instagram.svg"
import instagramIconContrast from "./images/InstagramContrast.svg"
import youtubeIcon from "./images/Youtube.svg"
import youtubeIconContrast from "./images/YoutubeContrast.svg"
import "./css/SocialComponent.scss"
import links from "../env.js"
import UserContext from "../Store/User_Context";

function SocialFooter() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
    <Grid container className={`social-footer ${userContext.contrast ? "high-social-footer" : ""}`}>

      <Grid className="social-logos">
        <a href={links["facebook"]} target="_blank" rel="noopener noreferrer">
          <img src={userContext.contrast ? facebookIconContrast : facebookIcon} alt="Facebook" />
        </a>
        <a href={links["instagram"]} target="_blank" rel="noopener noreferrer">
          <img src={userContext.contrast ? instagramIconContrast : instagramIcon} alt="Instagram" />
        </a>
        <a href={links["youtube"]} target="_blank" rel="noopener noreferrer">
          <img src={userContext.contrast ? youtubeIconContrast : youtubeIcon} alt="Youtube" />
        </a>
      </Grid>

    </Grid>
  )
}

export default SocialFooter;