const links = {
    "home": "/",
    "acessibilidade": "/acessibilidade",
    "mapaDoSite": "/mapa-do-site",
    "quemSomos": "/quem-somos",
    "publicacoes": "/publicacoes",
    "nossosParceiros": "/nossos-parceiros",
    "oQueFazemos": "/o-que-fazemos",
    "contato": "/contato",
    "agenda": "",
    "usuarios": "/usuarios",

    "facebook": "https://www.facebook.com/laboratoriodedadoseducacionais",
    "instagram": "https://www.instagram.com/laboratoriodedadoseducacionais",
    "youtube": "https://www.youtube.com/channel/UCijwE275pr8mHEoGqXu3yFg",
    "plataformaDadosEdu": "https://dadoseducacionais.c3sl.ufpr.br/plataforma",
    "simcaq": "https://www.simcaq.c3sl.ufpr.br/",
    "mapfor": "https://dadoseducacionais.c3sl.ufpr.br/mapfor-brasil/",
    "mapforPR": "https://dadoseducacionais.c3sl.ufpr.br/mapfor/",
    "c3sl": "https://www.c3sl.ufpr.br/",
    "mec" : "https://www.gov.br/mec/pt-br",
    "prefeituraDeVitoria": "https://www.vitoria.es.gov.br/seme",
    "fineduca": "https://fineduca.org.br/",
    "fineesp": "http://www.redefineesp.fe.usp.br/",

    "api": "https://www.simcaqhom.c3sl.ufpr.br/api/v1/publication/upload",
    "url": "https://www.simcaqhom.c3sl.ufpr.br/",
}

export const CLIENT_ID = '59cb9552535723d5ccf0bfd7';

export const CLIENT_SECRET = 'LDE';

export default links