import React, { useState, useEffect, useContext } from 'react'
import { Grid } from "@mui/material";

import UserContext from '../../Store/User_Context'
import links from '../../env';
import '../css/MapaComponent.scss'

import arrowIconLeft from '../images/Size=Small, Type=flecha back contrast green.svg'
import arrowIconLeftContrast from '../images/Size=Small, Type=flecha back contrast yellow.svg'

function MapaComponent() {

  const userContext = useContext(UserContext);
  let handleContrast = userContext.changeContrast;
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
    <Grid className={`map-container ${userContext.contrast ? "high-map-container" : ""}`} >
      <div className="route">
        <a href={links["home"]}>
          <img className='back-arrow' src={contrast ? arrowIconLeftContrast : arrowIconLeft} alt='voltar' />
        </a>
        <a className="home-link" href={links["home"]}>Homepage /  </a>
        <h1>Mapa do site</h1>
      </div>

      <div className='title'>Mapa do site</div>

      <Grid className='links-container'>
        <div className='page-link'>
          <h1 className='page-name'>Quem somos</h1>
          <p className='line' />
          <a className='link' href={links["quemSomos"]}>Quem somos</a>
        </div>
        <div className='page-link'>
          <h1 className='page-name'>Publicações</h1>
          <p className='line' />
          <a className='link' href={links["publicacoes"]}>Publicações</a>
        </div>
        <div className='page-link'>
          <h1 className='page-name'>O que fazemos</h1>
          <p className='line' />
          <a className='link' href={links["oQueFazemos"]}>O que fazemos</a>
        </div>
        {/* <div className='page-link'>
          <h1 className='page-name'>Agenda</h1>
          <p className='line' />
          <a className='link' href={links["agenda"]}>Agenda</a>
        </div> */}
        <div className='page-link'>
          <h1 className='page-name'>Nossos parceiros</h1>
          <p className='line' />
          <a className='link' href={links["nossosParceiros"]}>Nossos parceiros</a>
        </div>
        <div className='page-link'>
          <h1 className='page-name'>Contato</h1>
          <p className='line' />
          <a className='link' href={links["contato"]}>Contato</a>
        </div>
      </Grid>
    </Grid>

  )

}

export default MapaComponent;