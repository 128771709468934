import React, { useState, useEffect, useContext } from 'react';
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import UserContext from '../../Store/User_Context';

import '../css/IncludePublication.scss'

function DropdownFilters(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);


  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const category = {
    borderTop: '1px solid #525252',
    borderLeft: '1px solid #525252',
    borderRight: '1px solid #525252',
    borderBottom: contrast ? '1px solid #525252' : 'none',
    borderRadius: '4px',
    padding: '2px 10px',
    color: contrast ? '#fff' : '',
    
    "&.Mui-focused": {
      backgroundColor: contrast ? "black" : "white",
      borderBottom: "none",
    },

    '& .MuiSelect-icon':{
      color: contrast ? "#FFF000" : "#000",
    },

    "&::after": {
      borderColor: contrast? "#FFF000" : '#000',
    },

    "& .MuiPaper-root": {
      backgroundColor: "red",
    }
  }

  return (

    <FormControl variant="standard"
      sx={{
        m: 1, minWidth: props.tam,
        '@media (max-width: 599px)': {
          minWidth: 200,
        },
      }}>
      <InputLabel id="demo-simple-select-standard-label" required={props.required}
        sx={{
          color: contrast ? '#fff' : '#525252',
          fontWeight: 400,
          paddingTop: 1,
          background: contrast ? 'black' : '#fff',
          marginTop: -1,

          "&.Mui-focused": {
            color: contrast ? '#FFF000' : '#27486C',
            marginTop: -1,
          },
        }}
      >
        {props.text}
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={props.filter}
        onChange={props.handleChange}
        label="Escolha uma opção"
        sx={category}
      >
        {props.filds.map((option) => (
          <MenuItem value={option.value}
            sx={{
              backgroundColor: contrast ? '#000' : '#fff',
              color: contrast ? '#fff' : '#000',

              "&:hover": {
                backgroundColor: contrast ? '#000' : '#fff',
              },

              "&.Mui-selected ": {
                backgroundColor: contrast ? '#fff' : "",
                color: contrast ? '#000' : '',
              },

              "& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
                backgroundColor: "red",
              }
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DropdownFilters;
