import { useEffect, useState, createContext } from "react";

const PostedModeContext = createContext({
  posted: "",
  changePostedMode: () => { },
});

export function PostedModeContextProvider(props) {
  const [posted, setPosted] = useState(false);

  useEffect(() => {
    const getPostedMode = sessionStorage.getItem("posted");
    if (getPostedMode === "false" || getPostedMode === null) {
      setPosted(false);
    }
    else 
      setPosted(true);
  }, []);

  const handleChangePostedMode = (props) => {
    let newPosted = props;
    sessionStorage.setItem("posted", newPosted);
    setPosted(newPosted);
  };

  const context = {
    posted : posted,
    changePostedMode: handleChangePostedMode,
  };

  return (
    <PostedModeContext.Provider value={context}>
      {props.children}
    </PostedModeContext.Provider>
  );
}

export default PostedModeContext;
