import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import './css/ImageSlider.scss'

import largeImage1 from "./images/Size=Large, Page=homepage, Number=1.svg"
import smallImage1 from "./images/Size=Small, Page=homepage, Number=1.svg"
import largeImage2 from "./images/Size=Large, Page=homepage, Number=2.svg"
import smallImage2 from "./images/Size=Small, Page=homepage, Number=2.svg"
import largeImage3 from "./images/Size=Large, Page=homepage, Number=3.svg"
import smallImage3 from "./images/Size=Small, Page=homepage, Number=3.svg"
import largeImage4 from "./images/Size=Large, Page=homepage, Number=4.svg"
import smallImage4 from "./images/Size=Small, Page=homepage, Number=4.svg"
import largeImage5 from "./images/Size=Large, Page=homepage, Number=5.svg"
import smallImage5 from "./images/Size=Small, Page=homepage, Number=5.svg"
import largeImage6 from "./images/Size=Large, Page=homepage, Number=6.svg"
import smallImage6 from "./images/Size=Small, Page=homepage, Number=6.svg"
import largeImage7 from "./images/Size=Large, Page=homepage, Number=7.svg"
import smallImage7 from "./images/Size=Small, Page=homepage, Number=7.svg"
import largeImage8 from "./images/Size=Large, Page=homepage, Number=8.svg"
import smallImage8 from "./images/Size=Small, Page=homepage, Number=8.svg"

function ChooseSize(windowSize, largeImage, smallImage) {
    if (windowSize[0] <= 1279){
        return smallImage;
    } 
    return largeImage;
}

function ImageSlider () {
    const [currentIndex, setCurrentIndex] = useState();

    function handleChange(index) {
        setCurrentIndex(index);
    }

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });

    const imageData = [
        {   
            alt: "image1",
            image: ChooseSize(windowSize, largeImage1, smallImage1)
        },

        {   
            alt: "image2",
            image: ChooseSize(windowSize, largeImage2, smallImage2)
        },

        {   
            alt: "image3",
            image: ChooseSize(windowSize, largeImage3, smallImage3)
        },

        {   
            alt: "image4",
            image: ChooseSize(windowSize, largeImage4, smallImage4)
        },

        {   
            alt: "image5",
            image: ChooseSize(windowSize, largeImage5, smallImage5)
        },

        {   
            alt: "image6",
            image: ChooseSize(windowSize, largeImage6, smallImage6)
        },

        {   
            alt: "image7",
            image: ChooseSize(windowSize, largeImage7, smallImage7)
        },

        {   
            alt: "image8",
            image: ChooseSize(windowSize, largeImage8, smallImage8)
        },
    ];

    const renderSlides = imageData.map((slide) => (
        <div key={slide.alt}>
            <img src={slide.image} alt={slide.alt} />
        </div>
    ));

    return (
        // <div className="carousel">
            <Carousel
                showArrows={false}
                autoPlay={true}
                infiniteLoop={true}
                selectedItem={imageData[currentIndex]}
                onChange={handleChange}
                className="carousel-container"
                >
                {renderSlides}
            </Carousel>
        // </div>
    );
}

export default ImageSlider;
