import React, { useState, useEffect, useContext } from 'react';
import { InputLabel, FormControl, InputBase } from "@mui/material";
import { styled } from '@mui/material/styles';
import UserContext from '../../Store/User_Context';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  border: '1px solid #525252',
  borderRadius: '4px',
  padding: '8px 16px',
  height: 45,
  color: 'red',
}));

function LoginFormControl(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (

    <FormControl
      sx={{
        m: 1, minWidth: 320,

        '@media (max-width: 599px)': {
          minWidth: 200,
        }
      }} >
      <InputLabel id="demo-simple-select-standard-label"
        sx={{
          color: contrast ? '#FFF' : '#525252',
          fontWeight: 400,
          fontSize: 14,
          background: contrast ? 'black' : '#fff',

          "&.Mui-focused": {
            color: contrast ? '#FFF000' : '#31AEC4',
            paddingLeft: 1,
            paddingRight: 1,
          },
        }}

      >
        {props.name}
      </InputLabel>
      <BootstrapInput
        id="demo-customized-textbox"
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.handleChange}
        sx={{ color: contrast ? '#fff' : '#525252' }}
        type={props.type}
      />
    </FormControl>

  )

}

export default LoginFormControl;