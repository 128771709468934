import React, { useState, useContext, Fragment, useEffect } from "react";
import "../css/ContactFormComponent.scss";
import UserContext from "../../Store/User_Context.js";
import SuccessModal from "../SucessModal";
import PostedModeContext from "../../Store/PostedContext";

function ContactFormControl() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);
  const [posted, setPosted] = useState(false);
  const postedContext = useContext(PostedModeContext);
  const handlePosted = postedContext.changePostedMode;

  useEffect(() => {
    setPosted(postedContext.posted);
  }, [postedContext]);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  function verifyForm (formData) {
    if (formData.get('name') === "" || formData.get('email') === "" || formData.get('contents') === "") {
      return false;
    }
    return true;
  } 

  const handleSubmit = async (event) => {
    event.preventDefault(); 

    const formData = new FormData(event.target);

    if (!verifyForm(formData)) {
      alert('Por favor, preencha todos os campos.');
      return;
    } 

    try {
      const response = await fetch('https://simcaq.c3sl.ufpr.br/api/v1/message', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        handlePosted(true);
        document.getElementById("contact-form").reset();
      } else {
        console.error('Erro ao enviar formulário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  return (
    <Fragment>
      {posted ?  <SuccessModal type={"O feedback"} text={"enviado"} /> : " "}
      <form onSubmit={handleSubmit} id="contact-form">
        <div className={`input-field ${contrast ? 'high-input-field' : ''}`}>
          <p>Nome completo</p>
          <input
            type="text"
            placeholder="Insira seu nome completo"
            name="name"
          />
        </div>

        <div className={`input-field ${contrast ? 'high-input-field' : ''}`}>
          <p>E-mail</p>
          <input
            type="email"
            placeholder="Insira seu e-mail"
            name="email"
          />
        </div>

        <div className={`textarea-field ${contrast ? "high-textarea" : ""}`}>
          <p>Deixe sua mensagem</p>
          <textarea
            className="form-input"
            placeholder="Escreva aqui sua mensagem"
            name="contents"
          ></textarea>
        </div>

        <div className={`botao ${contrast ? "high-botao" : ""}`}>
          <button type="submit">Enviar</button>
        </div>
      </form>

    </Fragment>
  );
}

export default ContactFormControl;
