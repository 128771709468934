import { Grid } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import "./css/HeaderComponent.scss"
import links from "../env.js"
import UserContext from '../Store/User_Context'
import EditModeContext from '../Store/EditionMode'

import ldeLogo from "./images/logo LDE.svg"
import ldeLogoContrast from "./images/logoLDEContraste.svg"
import ldeLogoSmall from "./images/logo LDE Pequeno.svg"
import ldeLogoSmallContrast from "./images/logoLDESmallContraste.svg"
import MenuIcon from "./images/menu.svg"
import MenuIconContrast from "./images/menuContraste.svg"
import highContrastIcon from "./images/highContraste.svg"
import contrastIcon from "./images/contraste.svg"
import closeIcon from "./images/Size=Small, Type=fechar.svg"

//Choose LDE logo size
function ChooseSize(windowSize) {
  if (windowSize[0] <= 599) {
    //console.log(windowSize[0])
    return ldeLogoSmall;
  }
  return ldeLogo;
}

function ChooseSizeWithContrast(windowSize) {
  if (windowSize[0] <= 599) {
    //console.log(windowSize[0])
    return ldeLogoSmallContrast;
  }
  return ldeLogoContrast;
}

function HeaderComponent() {

  const location = useLocation()
  const userContext = useContext(UserContext);
  let handleContrast = userContext.changeContrast;
  const [contrast, setContrast] = useState(false);
  const editModeContext = useContext(EditModeContext);
  const [editmode, setEditmode] = useState(false);

  useEffect(() => {
    setEditmode(editModeContext.editmode)
  }, [editModeContext]);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const [textSizeChange, setTextSizeChange] = useState(0);
  const [visible, setVisible] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  //let excludeList = ['text2', 'MuiGrid'];

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  function fnBrowserDetect() {

    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      localStorage.getItem("countFont");
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }

    return browserName;
  }


  function resetFontSize() {
    let browser = fnBrowserDetect();
    if (browser === "firefox") {
      let classElements = document.getElementsByTagName("*");
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        elem.style.removeProperty("font-size");
      }
      localStorage.setItem("countFont", 0);
    }
    else {
      document.body.style.zoom = 1;
      localStorage.setItem("zoom", document.body.style.zoom);
    }

  }

  function increaseFontSize() {
    let browser = fnBrowserDetect();
    if (browser === "firefox") {
      if (localStorage.getItem("countFont") < 8) {
        let classElements = document.getElementsByTagName("*");
        for (let i = 0; i < classElements.length; i++) {
          let elem = classElements[i];
          let styles = getComputedStyle(elem);
          elem.style.fontSize =
            parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) *
            1.05 +
            "px";
        }
        localStorage.setItem(
          "countFont",
          Number(localStorage.getItem("countFont")) + 1
        );
      }
    }
    else {
      const currentZoom = Number(localStorage.getItem("zoom")) > 0 ? Number(localStorage.getItem("zoom")) : 1;
      let zoomFactor = currentZoom + 0.1;
      const zoomLimit = 1.5;
      if (zoomFactor <= zoomLimit) document.body.style.zoom = zoomFactor;
      localStorage.setItem("zoom", document.body.style.zoom);
    }

  }


  function decreaseFontSize() {
    let browser = fnBrowserDetect();
    if (browser === "firefox") {
      if (localStorage.getItem("countFont") >= 1) {
        let classElements = document.getElementsByTagName("*");
        for (let i = 0; i < classElements.length; i++) {
          let elem = classElements[i];
          let styles = getComputedStyle(elem);
          elem.style.fontSize =
            parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) /
            1.05 +
            "px";
        }
        localStorage.setItem(
          "countFont",
          Number(localStorage.getItem("countFont")) - 1
        );
      } else if (localStorage.getItem("countFont") === 1) resetFontSize();
    }
    else {
      const currentZoom = Number(localStorage.getItem("zoom"));
      const zoomLimit = 0.5;
      if (currentZoom > zoomLimit) {
        document.body.style.zoom = currentZoom - 0.1;
      }
      localStorage.setItem("zoom", document.body.style.zoom);
    }

  }

  function goFooter() {
    window.scrollTo(0, document.body.scrollHeight)
  }

  function goHeader() {
    window.scrollTo(document.body.scrollHeight, 0)
  }

  function goMenu() {
    window.scrollTo(0, document.getElementById('headerbox').offsetHeight)
  }

  var pressedAlt = false;

  document.onkeyup = function (e) {
    if (e.which === 18)
      pressedAlt = false;
  }

  document.onkeydown = function (e) {
    if (e.which === 18)
      pressedAlt = true;
    if (e.which === 51 && pressedAlt === true) {
      goFooter();
    }
    if (e.which === 50 && pressedAlt === true) {
      goHeader();
    }
    if (e.which === 49 && pressedAlt === true) {
      goMenu();
    }
  }

  return (

    <Grid container className={`header-container ${userContext.contrast ? "high-contrast-header" : ""}`} id='headerbox'>
      <Grid container className={`access-container ${userContext.contrast ? "high-contrast-container" : ""}`}>
        <div className="access-sections">
          <button className="link-button" onClick={goMenu} >Ir para o conteúdo 1</button>
          <button className="link-button" onClick={goHeader}>Ir para o menu 2</button>
          <button className="link-button" onClick={goFooter}>Ir para o rodapé 3</button>
        </div>
        <div className="access-sections-responsive">
          <button className="link-button" onClick={goMenu} >Conteúdo</button>
          <button className="link-button" onClick={goHeader}>Menu</button>
          <button className="link-button" onClick={goFooter}>Rodapé</button>
        </div>
        <div className="access-tools">
          <div className="access-tools-itens">
            <h1>Tamanho de texto:</h1>
            <button onClick={increaseFontSize} className={`contrast-button ${userContext.contrast ? "high-contrast-button" : ""}`} id="A+">
              A+
            </button>
            <button onClick={decreaseFontSize} className={`contrast-button ${userContext.contrast ? "high-contrast-button" : ""}`} id="A-">
              A-
            </button>
            <button onClick={resetFontSize} className={`contrast-button ${userContext.contrast ? "high-contrast-button" : ""}`} id="A">
              A
            </button>
          </div>
          <button onClick={handleContrast} className={`contrast-button ${userContext.contrast ? "high-contrast-button high-selected" : ""}`}>
            Contraste
            <img src={userContext.contrast ? highContrastIcon : contrastIcon} alt="Contraste" />
          </button>
          <div className="links-container">
            <a href={links["acessibilidade"]}
              className={location.pathname === links.acessibilidade ? contrast ? "high-selected" : "selected" : ""}
            >
              Acessibilidade
            </a>
            <a href={links["mapaDoSite"]}
              className={location.pathname === links.mapaDoSite ? contrast ? "high-selected" : "selected" : ""}
            >
              Mapa do site
            </a>
          </div>
        </div>
      </Grid>


      <Grid container className={`header-component ${userContext ? "high-header-component" : ""}`}>
        <Link to={links["home"]}>
          <img
            src={userContext.contrast ? ChooseSizeWithContrast(windowSize) : ChooseSize(windowSize)}
            alt="LDE"
            className='lde-logo'
          />
        </Link>

        <div className={`header-options ${visible ? 'opened-menu' : ''}`}>
          <img
            src={visible ? userContext.contrast ? closeIcon : closeIcon : userContext.contrast ? MenuIconContrast : MenuIcon}
            className="menu-icon"
            alt="Menu"
            onClick={() => setVisible(!visible)}
          />

          <Grid className='links-grid'>

            <Link
              to={links["home"]}
              onClick={() => visible ? setVisible(!visible) : {}}
              className={`header-option ${location.pathname === links.home ? contrast ? "high-selected" : "selected" : ""}`}
            >
              home
            </Link>

            <Link
              to={links["quemSomos"]}
              onClick={() => visible ? setVisible(!visible) : {}}
              className={`header-option ${location.pathname === links.quemSomos ? contrast ? "high-selected" : "selected" : ""}`}
            >
              quem somos
            </Link>

            <Link
              to={links["oQueFazemos"]}
              onClick={() => visible ? setVisible(!visible) : {}}
              className={`header-option ${location.pathname === links.oQueFazemos ? contrast ? "high-selected" : "selected" : ""}`}
            >
              o que fazemos
            </Link>

            <Link
              to={links["nossosParceiros"]}
              onClick={() => visible ? setVisible(!visible) : {}}
              className={`header-option ${location.pathname === links.nossosParceiros ? contrast ? "high-selected" : "selected" : ""}`}
            >
              nossos parceiros
            </Link>

            <Link
              to={links["contato"]}
              onClick={() => visible ? setVisible(!visible) : {}}
              className={`header-option ${location.pathname === links.contato ? contrast ? "high-selected" : "selected" : ""}`}
            >
              contato
            </Link>

            {editModeContext.editmode === "ativado" ?
              <Link
                to={links["usuarios"]}
                onClick={() => visible ? setVisible(!visible) : {}}
                className={`header-option ${location.pathname === links.usuarios ? contrast ? "high-selected" : "selected" : ""}`}
              >
                usuários
              </Link>
              : ""
            }

          </Grid>

        </div>
      </Grid>
    </Grid>
  )
}

export default HeaderComponent;