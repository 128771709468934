import React, { useRef, useState, useEffect, useContext } from 'react';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';

import '../css/IncludePublication.scss'
import links from '../../env';
import UserContext from '../../Store/User_Context';
import FormControlComponent from '../FormControl/FormControlComponent';
import DropdownComponent from '../Dropdowns/DropdownComponent';
import PostedModeContext from '../../Store/PostedContext';

import closeIcon from '../images/Size=Small, Type=fechar.svg'
import highCloseIcon from '../images/Size=Small, Type=fecharContrast.svg'

function IncludePublication(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const postedContext = useContext(PostedModeContext);
  const [posted, setPosted] = useState("");
  let handlePostedMode = postedContext.changePostedMode;

  useEffect(() => {
    setPosted(postedContext.posted)
  }, [postedContext]);

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(" ");
  const [filterInvalid, setFilterInvalid] = useState(false);
  const [text, setText] = useState(" ");
  const [textInvalid, setTextInvalid] = useState(false);
  const [title, setTitle] = useState("");
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [authors, setAuthors] = useState("");
  const [authorsInvalid, setAuthorsInvalid] = useState(false);
  const [organization, setOrganization] = useState("");
  const [organizationInvalid, setOrganizationInvalid] = useState(false);
  const [year, setYear] = useState("");
  const [yearInvalid, setYearInvalid] = useState(false);
  const [link, setLink] = useState("");
  const [linkInvalid, setLinkInvalid] = useState(false);
  const [draft, setDraft] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [volume, setVolume] = useState("");
  const [page, setPage] = useState("");
  const [checked, setChecked] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Component's style //

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 624,
    heigth: 810,
    bgcolor: contrast ? 'black' : 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    paddingBottom: 8,
    border: contrast ? '1px solid #fff' : '',

    '@media (max-width: 599px)': {
      width: 300,
      height: 600,
      top: '50%',
      overflowY: "auto",
    },

    '@media (min-width: 600px) and (max-width: 1200px)': {
      top: '50%',
      height: 880,
      overflowY: "auto",
    },

    '@media (min-width: 1201px) and (max-width: 1600px)': {
      top: '50%',
      height: 740,
      overflowY: "auto",
    },
    
    ' @media (min-width: 1601px)': {
      top: '50%',
      height: 800,
      overflowY: "auto",
    },
  };

  const button = {
    color: 'rgba(23, 43, 64, 1)',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    textAlign: 'left',
    lineHeight: '136.18%',
    minWidth: 20,
    padding: '0 0 0 0',
  };

  const titleStyle = {
    color: contrast ? '#FFFF00' : '#27486C',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '136.18%',
    alignItems: 'center',
    paddingLeft: '210px',
    paddingTop: '50px',
    paddingBottom: '50px',

    '@media (max-width: 599px)': {
      paddingLeft: '50px',
    }
  }

  // Functions to change variable's values //

  const handleChangeCategory = (event) => {
    setFilter(event.target.value);
  };

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  }

  const handleChangeAuthors = (event) => {
    setAuthors(event.target.value);
  }

  const handleChangeOrganization = (event) => {
    setOrganization(event.target.value);
  }

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  }

  const handleChangeLink = (event) => {
    setLink(event.target.value);
  }

  const handleChangeVolume = (event) => {
    setVolume(event.target.value);
  }

  const handleChangePage = (event) => {
    setPage(event.target.value);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  // Dropdown values //

  const publicationOptions = [
    { value: "Artigo", label: "Artigo" },
    { value: "Tese", label: "Tese" },
    { value: "Dissertação", label: "Dissertação" },
    { value: "Relatório", label: "Relatório" },
    { value: "Periódico", label: "Periódico" },
  ];

  const textOptions = [
    { value: "Baixar", label: "Baixar" },
    { value: "Acessar", label: "Acessar" },
  ]

  // File upload code //

  const filesElement = useRef(null);

  const [fileName, setFileName] = useState('');

  const sendFileName = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
  };

  // Build JSON code //

  let dataJson = {
    "categoria": filter,
    "title": title,
    "autores": authors,
    "organizacao": organization,
    "ano": year,
    "texto": text,
    "upload": fileName,
    "link": link,
    "homepage": checked,
    "rascunho": draft,
    "volume": volume,
    "pagina": page,
  }

  function verify() {
    if (filter === " ") {
      setFilterInvalid(true);
      setSucess(false);
    } else
      setSucess(true);
    if (title === "") {
      setTitleInvalid(true);
      setSucess(false);
    } else
      setSucess(true);
    if (authors === "") {
      setAuthorsInvalid(true);
      setSucess(false);
    } else
      setSucess(true);
    if (organization === "") {
      setOrganizationInvalid(true);
      setSucess(false);
    } else
      setSucess(true);
    if (year === "") {
      setYearInvalid(true);
      setSucess(false);
    } else
      setSucess(true);
    if (text === " ") {
      setTextInvalid(true);
      setSucess(false);
    } else
      setSucess(true);
    if (link === "") {
      setLinkInvalid(true);
      setSucess(false);
    } else
      setSucess(true);
  }

  function sendPublication() {
    verify();
    if (sucess) {
      const dataForm = new FormData();
      if (text == "Baixar") {
        for (const file of filesElement.current.files) {
          dataForm.append('file', file);
        }
      }
      dataJson.rascunho = false;
      dataForm.append('data', JSON.stringify(dataJson));
      const rp = fetch(`${links.url}api/v1/publication`, {
        method: 'POST',
        body: dataForm,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      }).then(response => {
        if (response.status === 200) {
          setChecked(false);
          handlePostedMode(true);
        }
      })
      setSucess(false);
      handleClose();
    }
  }

  function sendDraft() {
    setDraft(true);

    const dataForm = new FormData();
    if (text == "Baixar") {
      for (const file of filesElement.current.files) {
        dataForm.append('file', file);
      }
    }
    dataJson.rascunho = true;
    dataForm.append('data', JSON.stringify(dataJson));

    const rp = fetch(`${links.url}api/v1/publication`, {
      method: 'POST',
      body: dataForm,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    });
    console.log(rp);

    setChecked(false);
  }

  return (
    <div>
      <Button onClick={handleOpen} sx={button}>
        <img src={props.image} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close-button' onClick={handleClose}>
            <img src={contrast ? highCloseIcon : closeIcon} alt="fechar" />
          </button>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={titleStyle}>
            Nova publicação
          </Typography>

          <div>

            <DropdownComponent
              text={"Selecione a categoria da publicação"}
              value={filter}
              handleChange={handleChangeCategory}
              filds={publicationOptions}
              setText={setFilter}
              valid={filterInvalid}
              setValid={setFilterInvalid}
            />

            <FormControlComponent
              name={"Título"}
              value={title}
              handleChange={handleChangeTitle}
              setText={setTitle}
              required={true}
              valid={titleInvalid}
            />

            <FormControlComponent
              name={"Autores/as da publicação"}
              value={authors}
              handleChange={handleChangeAuthors}
              setText={setAuthors}
              required={true}
              valid={authorsInvalid}
            />

            <FormControlComponent
              name={"Organização publicada (informar edição se houver)"}
              value={organization}
              handleChange={handleChangeOrganization}
              setText={setOrganization}
              required={true}
              valid={organizationInvalid}
            />

            <FormControlComponent
              name={"Volume (v. xx)"}
              value={volume}
              handleChange={handleChangeVolume}
              setText={setVolume}
              required={false}
            />

            <FormControlComponent
              name={"Paginação (p. xx - xx)"}
              value={page}
              handleChange={handleChangePage}
              setText={setPage}
              required={false}
            />

            <FormControlComponent
              name={"Ano de publicação"}
              value={year}
              handleChange={handleChangeYear}
              setText={setYear}
              required={true}
              valid={yearInvalid}
            />

            <DropdownComponent
              text={"Selecione o texto do botão"}
              value={text}
              handleChange={handleChangeText}
              filds={textOptions}
              setText={setText}
              valid={textInvalid}
            />

            {text === "Acessar" ?
              <FormControlComponent
                name={"Link"}
                value={link}
                handleChange={handleChangeLink}
                setText={setLink}
                required={true}
                valid={linkInvalid}
              />
              :
              text === "Baixar" ?
                <div className='upload'>
                  <label htmlFor="arquivo" className={`search-button ${contrast ? 'high-search-button' : ''}`} required={true}>Procurar arquivo</label>
                  <input type="file" multiple ref={filesElement} name="arquivo" id='arquivo' className='input-button' onChange={sendFileName} required={true} />
                  <p className={`file-name ${contrast ? 'high-file-name' : ''}`}>{fileName}</p>
                </div>
                :
                " "
            }
            <div className={`check ${contrast ? "high-check" : ""}`}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Colocar a publicação em destaque na homepage</span>
            </div>
          </div>
          <div className={`buttons ${contrast ? "high-buttons" : ""}`}>
            <button className='save'
              onClick={event => {
                sendDraft();
                handleClose();
              }}
            >
              Salvar rascunho
            </button>
            <button className='post'
              onClick={sendPublication}
            >
              Postar
            </button>
          </div>
        </Box>

      </Modal>
    </div>
  )
}

export default IncludePublication;