import React from 'react';

import './App.css';
import LDE from './LDE'

function App() {
    return (
        <LDE />
    );
}

export default App;
