import React, { useState, useEffect, useContext } from 'react';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import './css/ResetPassword.scss'
import UserContext from '../Store/User_Context';
import links from '../env';
import LoginFormControl from './FormControl/LoginFormControl';

import closeIcon from './images/Size=Small, Type=fechar.svg'
import enterIcon from './images/Size=Small, Type=entrar.svg'
import enterIconContrast from './images/Size=Small, Type=entrarContrast.svg'


function ResetPasswordModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  const [email, setEmail] = useState("");

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    heigth: 130,
    bgcolor: contrast ? '#000' : 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    border: contrast ? '1px solid #fff' : 'none',
    paddingBottom: 8,

    '@media (max-width: 599px)': {
      width: 250,
      height: 230,
    },
  };

  const button = {
    color: contrast ? '#FFF000' : '#FD4BB0',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    textAlign: 'center',
    lineHeight: '136.18%'
  };

  function ResetPassword() {
    fetch(`${links.url}api/v1/user/reset/password?email=` + email, {
      method: 'GET'
    }).then(response => {
      if (response.status == 404) {
        throw new Error("Erro")
      }
    });
  }

  return (
    <div>
      <Button onClick={handleOpen} sx={button}>Esqueci minha senha</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close' onClick={handleClose}>
            <img src={closeIcon} alt="fechar" />
          </button>
          <div className={`title ${contrast ? 'high-title' : ''}`}>
            REDEFINIÇÃO SENHA!
          </div>
          <div className={`text ${contrast ? 'high-text' : ''}`}>
            Enviamos para o seu email um link para recuperação de senha!
          </div>
          <LoginFormControl name={"E-mail"} value={email} handleChange={handleChangeEmail} type={"string"} />
          <div className='button'>
            <button className={`enter-button ${contrast ? 'high-enter-button' : ''}`} onClick={ResetPassword}>
              Enviar
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ResetPasswordModal;