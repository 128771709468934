import { useEffect, useState, createContext } from "react";

const UserContext = createContext({
    contrast: "",
    changeContrast: () => {},
});
  
  export function UserContextProvider(props) {
    const [contrast, setContrast] = useState("");
  
    useEffect(() => {
      const getContrast = localStorage.getItem("contrast");
      setContrast(getContrast || "");
    }, []);
  
    const handleChangeContrast = () => {
      let newContrast = contrast === "contrast" ? "" : "contrast";
      localStorage.setItem("contrast", newContrast);
      setContrast(newContrast);
    };
  
    const context = {
      contrast: contrast,
      changeContrast: handleChangeContrast,
    };
  
    return (
      <UserContext.Provider value={context}>
        {props.children}
      </UserContext.Provider>
    );
  }
  
export default UserContext;