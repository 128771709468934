import React, { useRef, useState, useEffect, useContext } from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select, InputBase } from "@mui/material";
import UserContext from '../../Store/User_Context';
import PostedModeContext from '../../Store/PostedContext';

import '../css/IncludePublication.scss'

function DropdownComponent(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  const [filter, setFilter] = useState(props.value)

  const handleChangeCategory = (event) => {
    setFilter(event.target.value);
    props.setText(event.target.value);
  };

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const category = {
    color: contrast ? '#fff' : '#000',
    borderTop: '1px solid #525252',
    borderLeft: '1px solid #525252',
    borderRight: '1px solid #525252',
    borderBottom: contrast ? '1px solid #525252' : 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    
    "&.Mui-focused": {
      backgroundColor: contrast ? "black" : "white",
      borderBottom: "none",
    },

    '& .MuiSelect-icon':{
      color: contrast ? "#FFF000" : "#000",
    },

    "&::after": {
      borderColor: contrast? "#FFF000" : '#000',
    },
  }

  return (

    <FormControl variant="standard"
      error={props.valid}
      sx={{
        m: 1, minWidth: 600,

        '@media (max-width: 599px)': {
          minWidth: 300,
        },
      }}>
      <InputLabel id="demo-simple-select-standard-label" required={true}
        sx={{
          color: contrast ? '#fff' : '#525252',
          fontWeight: 400,
          paddingTop: 1,
          background: contrast ? 'black' : '#fff',
          marginTop: -1,

          "&.Mui-focused": {
            color: contrast ? '#FFF000' : '#27486C',
            marginTop: -1,
          },
        }}
      >
        {props.text}
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={filter}
        onChange={handleChangeCategory}
        label="Escolha uma opção"
        sx={category}
      >
        {props.filds.map((option) => (
          <MenuItem value={option.value}
            sx={{
              backgroundColor: contrast ? '#000' : '#fff',
              color: contrast ? '#fff' : '#000',

              "&:hover": {
                backgroundColor: contrast ? '#000' : '#fff',
              },

              "& .Mui-selected ": {
                backgroundColor: contrast ? '#fff' : "",
                color: contrast ? '#000' : '',
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DropdownComponent;
