import React, { useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import links from "../../env.js";
import UserContext from "../../Store/User_Context.js";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function UsersTable() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  useEffect (() => {
    const rp = fetch(`${links.url}api/v1/email`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      console.log(data);
      setUsers(data);
    });
  }, []);

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
  ];

  const tableDesign = { 
    color: contrast ? '#fff' : '#000',

    'button' : {
      color: contrast ? '#FFF000' : '#31aec4',
    },

    '.MuiDataGrid-footerContainer' : {
      color: contrast ? '#FFF000' : '#31aec4',
    }, 

    '.MuiToolbar-root' : {
      color: contrast ? '#FFF000' : '#000',
    },

    '.MuiButtonBase-root' : {
      color: contrast ? '#FFF000' : '#000'
    }
  } 


  return (

    <Grid className={`sendEmail-container ${contrast ? "high-page-container" : ""}`}>
      <Grid container className="principal-container">
        <Grid item xs={12} className="link-container">
          <Link to={links["home"]} className="link">
            Homepage / {" "}
          </Link>
          <Link to={links["usuarios"]} className="link_atual">
            {" "}
            Usuários
          </Link>
        </Grid>

        <Grid item xs={12} className="titulo-container">
          <h1 className="titulo">Usuários</h1>
        </Grid>
      </Grid>

      <div className="dataGrid-container">
        <DataGrid
          rows={users}
          columns={columns}
          getRowId={(row) => row.name + row.email}
          pageSize={10}
          slots={{
            toolbar: CustomToolbar,
          }}
          sx={tableDesign}
        />
      </div>

    </Grid>

  );
}

export default UsersTable;
