import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import '../css/AcessibilityComponent.scss'
import links from '../../env';
import UserContext from '../../Store/User_Context';

function AcessibilityComponent() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
    <Grid className={`acessibility-container ${userContext.contrast ? "high-acessibility-container" : ""}`}>
      <div className="route">
        <a href={links["home"]}>Homepage /</a>
        <p>Acessibilidade</p>
      </div>
      <div className="title">Acessibilidade</div>
      <Grid className="text">
        <p>
          O termo acessibilidade significa incluir a pessoa com deficiência na
          participação de atividades como o uso de produtos, serviços e informações.
          Alguns exemplos são os prédios com rampas de acesso e os banheiros adaptados
          para pessoas com deficiência.
        </p>
        <p>
          Na internet, acessibilidade refere-se principalmente às recomendações do WCAG
          (World Content Accessibility Guide) do W3C e, no caso do Governo Brasileiro, ao
          e-MAG (Modelo de Acessibilidade em Governo Eletrônico). O e-MAG está alinhado às
          recomendações internacionais e estabelece padrões de comportamento acessíveis para
          sites governamentais. Na parte superior do portal existe uma barra de acessibilidade
          onde se encontram atalhos de navegação padronizados e a opção para alterar o
          contraste. Essas ferramentas estão disponíveis em todas as páginas do portal.
        </p>
        <p>
          Os padrões de atalhos do governo federal são:
        </p>
        <p>
          Teclando-se <span className="green">Alt + 1</span> em qualquer página do portal,
          chega-se diretamente ao começo do conteúdo principal da página.
        </p>
        <p>
          Teclando-se <span className="green">Alt + 2</span> em qualquer página do portal,
          chega-se diretamente ao início do menu principal.
        </p>
        <p>
          Teclando-se <span className="green">Alt + 3</span> em qualquer página do portal,
          chega-se diretamente ao rodapé do site.
        </p>
        <p>
          Esses atalhos valem para o navegador <span className="green">Chrome</span>, mas
          existem algumas variações para outros navegadores. Ao utilizar o
          <span className="pink"> Internet Explorer</span>, por exemplo, é preciso apertar
          o botão Enter do seu teclado após uma das combinações acima. Portanto, para chegar
          ao rodapé do site é preciso pressionar <span className="pink">Alt + 3 e depois Enter</span>.
        </p>
        <p>
          No caso do <span className="green">Firefox</span>, em vez de Alt + número,
          tecle simultaneamente <span className="green">Alt + Shift + número</span>.
          Sendo Firefox no Mac OS, em vez de Alt + Shift + número, tecle simultaneamente Ctrl +
          Alt + número.
        </p>
      </Grid>
    </Grid>

  )

}

export default AcessibilityComponent;