import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CounterFunction from './CounterFunction';

import UserContext from '../../Store/User_Context';

import closeIcon from '../images/Size=Small, Type=fechar.svg'
import { Link } from 'react-router-dom';

function SimcaqRedirection() {
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false)};

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: contrast ? '#000' : 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    border: contrast ? '1px solid #fff' : 'none',

    '@media (max-width: 599px)': {
      width: 250,
      height: 430,
    },
  };

  const title = {
    color: contrast ? '#FFFFFF' : '#000000',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '136.18%',
    alignItems: 'center',
    paddingTop: '40px',
  }

  const text = {
    paddingTop: "10px",
  }

  const button = {
    color: contrast ? '#FFF' : '#31AEC4',
    backgroundColor: contrast ? "#FFF000" : '#FFFFFF',
    border: '2px solid #31AEC4',
    minWidth: '140px',
    minHeight: '10px',
    padding: '8px 5px 8px',
    gap: '4px',
    borderRadius: '4px',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    lineHeight: '136.18%',
  };

  const linkStyle = {
    color: contrast ? '#FFF000' : '#31AEC4',
  }

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={button}
      >
        Acesse a plataforma
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close' onClick={handleClose}>
            <img src={closeIcon} alt="fechar" />
          </button>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={title}>
            O SIMCAQ não está mais sendo desenvolvido no C3SL/UFPR.
          </Typography>
          <p> Ele está no seguinte endereço:  <Link style={linkStyle} to='https://simcaq.ufg.br/'> https://simcaq.ufg.br/</Link> </p>          
          <Grid sx={text}>
            <p>Você será redirecionado em  <CounterFunction /> segundos...</p>
            <p>Caso o redirecionamento não ocorra automaticamente, clique   <Link style={linkStyle} to='https://simcaq.ufg.br/'> aqui</Link></p>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", gap: '30px', paddingTop: '20px' }}>
            <Button onClick={handleClose} sx={button}>
              Voltar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default SimcaqRedirection;