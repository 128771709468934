import { useEffect, useState, createContext } from "react";

const EditmodeContext = createContext({
  editmode: "",
  changeEditmode: () => {},
});

export function EditmodeContextProvider(props) {
  const [editmode, setEditmode] = useState("");

  useEffect(() => {
    const getEditmode = sessionStorage.getItem("editmode"); 
    setEditmode(getEditmode || "");
  }, []);

  const handleChangeEditmode = (props) => {
    let newEditmode = props ? "ativado" : "desativado";
    sessionStorage.setItem("editmode", newEditmode); 
    setEditmode(newEditmode);
  };

  const context = {
    editmode: editmode,
    changeEditmode: handleChangeEditmode, 
  };

  return (
    <EditmodeContext.Provider value={context}>
      {props.children}
    </EditmodeContext.Provider>
  );
}

export default EditmodeContext;
