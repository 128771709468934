import React, { useState, useEffect, useContext } from 'react'
import { Card, Grid, Pagination } from "@mui/material";

import UserContext from '../../Store/User_Context'
import '../css/PublicationsPage.scss'
import CardLabel from '../CardLabel';

function PublicationsGrade(props) {

  const userContext = useContext(UserContext);
  let handleContrast = userContext.changeContrast;
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
        <Grid className='labels-container'>
          {props.posts.map(post => (
            <CardLabel id={post.id} accessType={post.text} />
          ))}
        </Grid>
  )

}

export default PublicationsGrade;