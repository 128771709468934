import React, { useContext, useEffect, useState } from "react";

import "../css/EditionOptions.scss"
import IncludePublication from "./IncludePublications";
import PublicationsComponent from "./PublicationsComponent";
import UserContext from '../../Store/User_Context';
import SuccessModal from "../SucessModal";

import includeIcon from '../images/Size=Small, Type=zoom in.svg'
import includeIconContrast from '../images/Size=Small, Type=zoomContrast.svg'
import editIcon from '../images/Size=Small, Type=editar.svg'
import editIconContrast from '../images/Size=Small, Type=editarContrast.svg'
import PostedModeContext from "../../Store/PostedContext";

function EditionOptionPublication() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);
  const [posted, setPosted] = useState(false);
  const postedContext = useContext(PostedModeContext)
  let handlePostedMode = postedContext.changePostedMode;

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  useEffect(() => {
    setPosted(postedContext.posted);
  }, [postedContext]);


  return (
    <div className={`box ${contrast ? 'high-box-options' : ''}`}>
      <div className="edition-button" >
        <IncludePublication image={contrast ? includeIconContrast : includeIcon} />

        { posted ? 
          <div>
            <SuccessModal type={"publicação"} text={"postada"} />
          </div>
          :
          ""
        }
      </div>
      <div className="edition-button">
        <PublicationsComponent image={contrast ? editIconContrast : editIcon} />
      </div>
    </div>
  )
}

export default EditionOptionPublication;