import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import '../css/HomeComponent.scss'
import UserContext from '../../Store/User_Context';
import links from '../../env';
import CardLabel from '../CardLabel';
//import ScheduleCard from '../ScheduleCard';
import ImageSlider from '../ImageSlider';
import EditionOptionsPublications from '../PublicationComponents/EditionOptionsPublication';
//import EditionOptionsSchedule from '../ActivitiesComponents/EditionOptionsSchedule';
import EditModeContext from '../../Store/EditionMode';
import PostedModeContext from "../../Store/PostedContext";
import DeleteModeContext from '../../Store/DeleteMode';
import SimcaqRedirection from './SimcaqRedirectionModal';

import arrowIconDown from '../images/Size=Small, Type=flecha down.svg'
import arrowIconDownContrast from '../images/Size=Small, Type=flecha down contrast.svg'
import arrowIconRight from '../images/Size=Small, Type=flecha next.svg'
import arrowIconRightContrast from '../images/Size=Small, Type=flecha next contrast.svg'
import logoPlataforma from '../images/Size=Medium, Brand=PDE, Type=original, Signature=sem.svg'
import logoPlataformaConstrast from '../images/Size=Medium, Brand=PDE, Type=original 2, Signature=sem.svg'
import logoSimcaq from '../images/Size=Medium, Brand=simcaq, Type=original, Signature=sem.svg'
import logoMapFor from '../images/Size=Medium, Brand=mapfor, Type=original, Signature=sem.svg'
import logoMapForContrast from '../images/Size=Medium, Brand=mapfor, Type=pb, Signature=sem.svg'
import logoMapForPR from '../images/Size=Medium, Brand=mapforPR, Type=original, Signature=sem.svg'
import logoMapForPRContraste from '../images/Size=Medium, Brand=mapforPR, Type=pb, Signature=sem.svg'
import ufprLogo from '../images/logo_UFPR.svg'
import ufprLogoContrast from '../images/Logo=UFPR, Size=Large, Type=Mono negativa.svg'
import ufgLogo from "../images/Marca_UFG_cor_completa_horizontal .svg"
import ufgLogoContrast from '../images/Logo=UFG, Size=Large, Type=PB positiva.svg'
import WarningModal from '../WarningModal';

function HomePageComponent() {

  const userContext = useContext(UserContext);
  const editModeContext = useContext(EditModeContext);
  const [contrast, setContrast] = useState(false);
  const [editmode, setEditmode] = useState(false);
  const [posted, setPosted] = useState(false);
  const postedContext = useContext(PostedModeContext);
  const deleteContext = useContext(DeleteModeContext);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  useEffect(() => {
    setEditmode(editModeContext.editmode)
  }, [editModeContext]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    setPosted(postedContext.posted);
  }, [postedContext])

  useEffect(() => {
    setDeleted(deleteContext.deleted)
  }, [deleteContext]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  function goHeader() {
    window.scrollTo(document.body.scrollHeight, 0)
  }

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch(`${links.url}api/v1/publication/homepage?page=${1}&pageSize=${3}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      //console.log(data);
      setPosts(data.data);
      //console.log(data.totalCount);
    }).catch(error => {
      // Handle any errors that occur during the promise
      //console.error(error);
    });

  }, [posted, deleted]);

  // const [userAgent, setUserAgent] = useState(0);
  // var sUsrAg = navigator.userAgent;

  // if(sUsrAg.indexOf("Firefox") > -1) {
  //   setUserAgent(1);
  // }

    const [userAgent, setUserAgent] = useState(0);
  
    useEffect(() => {
      var sUsrAg = navigator.userAgent;
  
      if (sUsrAg.indexOf("Firefox") > -1) {
        setUserAgent(1);
      }
    }, []); // Certifique-se de passar um array de dependências vazio para evitar loops infinitos
    
    const [seeModal, setSeeModal] = useState(true)

    useEffect (() => {
      setSeeModal(localStorage.getItem("incompatibility"));
    })

  return (
    <Grid className={`home-container ${userContext.contrast ? "high-home-container" : ""}`}>
      {editModeContext.editmode === "ativado" ?

        <div className='banner' id='editionmode'>
          {goHeader()}
          Você está no modo edição
        </div>
        :
        <div />
      }
      <Grid className='central-container'>
        {userAgent &&  seeModal == 'true' ? <WarningModal /> : ""}
        <Grid className="title-container">
          <div className="title-box">
            <h1>Laboratório</h1>
            <h1>de Dados</h1>
            <h1>Educacionais</h1>
          </div>
          <div className='subtitle-box'>
            <h1>Grupo de pesquisa que realiza estudos e formação em política e gestão da educação</h1>
          </div>

          <h1 className='show-more-box'>
            Conheça nossas plataformas
            <img src={userContext.contrast ? arrowIconDownContrast : arrowIconDown} alt="para baixo" />
          </h1>
        </Grid>

        <ImageSlider />
      </Grid>

      <Grid className={`plataforms-container ${userContext.contrast ? "high-box-container" : ""}`}>
        <div className='plataform-box'>
          <img src={userContext.contrast ? logoPlataformaConstrast : logoPlataforma} alt='Plataforma de dados educacionais' />
          <p>Consulte os dados da EducaçãoBásica e do Ensino Superior
            em série histórica, com diferentes níveis de desagregação.
          </p>
          <a className='plataforms-button dados-edu' href={links["plataformaDadosEdu"]}>Acesse a plataforma</a>
        </div>
        <div className='plataform-box'>
          <img src={logoSimcaq} alt='Simcaq' />
          <p>Calcule o custo da oferta de ensino em condições de qualidade nas escolas
            públicas de educação básica, e faça o planejamento orçamentário.
          </p>
          {/* <a className='plataforms-button simcaq' href={links["simcaq"]}>Acesse a plataforma</a> */}
          <SimcaqRedirection />
        </div>
        <div className='plataform-box'>
          <img src={userContext.contrast ? logoMapForContrast : logoMapFor} alt='MapFor' />
          <p>Consulte o painel de indicadores e mapas com informações sobre
            a formação dos professores da educação básica.
          </p>
          <a className='plataforms-button mapfor' href={links["mapfor"]}>Acesse a plataforma</a>
        </div>
        <div className='plataform-box mapforbr'>
          <img src={userContext.contrast ? logoMapForPRContraste : logoMapForPR} alt="Mapfor Paraná" />
          <p>
            Consulte o painel de indicadores e
            mapas com informações sobre a formação dos professores da educação básica.
          </p>
          <a className='plataforms-button mapfor' href={links["mapforPR"]}>Acesse a plataforma</a>
        </div>
      </Grid>

      <div className='realization-box'>
        <p>Realizado por </p>
        <img src={userContext.contrast ? ufprLogoContrast : ufprLogo} alt="UFPR" />
        <img src={userContext.contrast ? ufgLogoContrast : ufgLogo} alt="UFG" />

      </div>
      <div className='publications'>
        <h1>Publicações</h1>
        <p>
          As publicações aqui divulgadas são realizadas no âmbito de cada projeto de pesquisa pelos
          integrantes da equipe (e também em parceria com pesquisadores externos) que subsidiam o
          desenvolvimento, a manutenção e a evolução dos produtos do grupo LDE.
        </p>

      </div>

      <Grid container className='boxes-container'>
        {editModeContext.editmode === "ativado" ?
          <div className='edition-options'>
            <EditionOptionsPublications />
          </div>
          :
          <div />}

          <Grid className='posts'>
            {posts.map(post => (
              <CardLabel id={post.id} accessType={post.text} />
            ))}
          </Grid>
      </Grid>

      <div className='more-publications'>
        <a href={links["publicacoes"]} className='show-more'>
          Mais publicações
          <img src={userContext.contrast ? arrowIconRightContrast : arrowIconRight} alt="Mais publicações" />
        </a>
      </div>

      {/* <div className='schedule'>
        <h1>Agenda</h1>
        <p>
          Nesse espaço divulgamos as atividades mais recentes as quais o grupo participa ou
          organiza apresentando seu trabalho.
        </p>
      </div>

      <Grid className='schedule-boxes'>
        {editModeContext.editmode === "ativado" ?
          <div className='edition-options'>
            <EditionOptionsSchedule />
          </div>
          :
          <div />
        }
        <ScheduleCard />
        <ScheduleCard />
        <ScheduleCard />
      </Grid>

      <div className='more-publications'>
        <a href={links["agenda"]} className='show-more'>
          Agenda completa
          <img src={userContext.contrast ? arrowIconRightContrast : arrowIconRight} alt="Mais publicações" />
        </a>
      </div> */}
    </Grid>
  )

}

export default HomePageComponent;