import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import EditmodeContext from '../Store/EditionMode';
import UserContext from '../Store/User_Context';
import successIcon from './images/Size=Large, Type=sucesso.svg'
import DeleteModeContext from '../Store/DeleteMode';
import PostedModeContext from '../Store/PostedContext';

import closeIcon from './images/Size=Small, Type=fechar.svg'


function SuccessModal(props) {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);

  const deleteContext = useContext(DeleteModeContext);
  const [deleted, setDeleted] = useState(false);
  let handleDeleteMode = deleteContext.changeDeleteMode;

  const postedContext = useContext(PostedModeContext);
  const [posted, setPosted] = useState(false);
  let handlePostedMode = postedContext.changePostedMode;

  useEffect(() => {
    setDeleted(deleteContext.deleted)
  }, [deleteContext]);

  useEffect(() => {
    setPosted(postedContext.posted);
  }, [postedContext]);

  const handleClose = () => {
    handleDeleteMode(false);
    handlePostedMode(false);
    setOpen(false);
  }

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const [editmode, setEditmode] = useState("");
  const editmodeContext = useContext(EditmodeContext);
  let handleEditmode = editmodeContext.changeEditmode;


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 230,
    heigth: 300,
    bgcolor: contrast ? '#000' : 'background.paper',
    boxShadow: 4,
    p: 4,
    borderRadius: '10px',
    border: contrast ? '1px solid #fff' : 'none',
  };

  const title = {
    color: contrast ? '#FFF000' : '#99BB32',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '136.18%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    gap: "25px",

  }

  const button = {
    color: contrast ? '#FFF' : '#fff',
    backgroundColor: '#99BB32',
    borderRadius: '4px',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    textAlign: 'left',
    lineHeight: '136.18%'
  };

  const container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingLeft: '20px',
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close' onClick={handleClose}>
            <img src={closeIcon} alt="fechar" width={15}/>
          </button>
          <div style={container}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={title}>
              <img src={successIcon} alt="Success" /> 
              {props.type} foi {props.text} com sucesso!
            </Typography>
            <Grid>
              <Button onClick={handleClose} sx={button}>
                Ok
              </Button>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SuccessModal;