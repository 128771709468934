  import React, { useState, useEffect, useContext } from 'react';
  import { Box, Grid } from "@mui/material";
  import Button from '@mui/material/Button';
  import Typography from '@mui/material/Typography';
  import Modal from '@mui/material/Modal';

  import EditmodeContext from '../Store/EditionMode';
  import UserContext from '../Store/User_Context';
  import links from '../env';
  import DeleteModeContext from '../Store/DeleteMode';

  import closeIcon from './images/Size=Small, Type=fechar.svg'
  import deleteIcon from './images/Size=Small, Type=lixeira.svg'


  function DeleteModal(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const userContext = useContext(UserContext);
    const [contrast, setContrast] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteError, setDeleteError] = useState(false);

    useEffect(() => {
      setContrast(userContext.contrast)
    }, [userContext]);

    const [editmode, setEditmode] = useState("");
    const editmodeContext = useContext(EditmodeContext);
    let handleEditmode = editmodeContext.changeEditmode;

    const deleteContext = useContext(DeleteModeContext);
    const [deleted, setDeleted] = useState("");
    let handleDeleteMode = deleteContext.changeDeleteMode;
  
    useEffect(() => {
      setDeleted(deleteContext.deleted)
    }, [DeleteModeContext]);
  

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 350,
      heigth: 450,
      bgcolor: contrast ? '#000' : 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: '10px',
      border: contrast ? '1px solid #fff' : 'none',
      paddingBottom: 8,

      '@media (max-width: 599px)': {
        width: 250,
        height: 380,
      },
    };

    const button = {
      padding: '0 0 0 0',
  
      '@media (max-width: 599px)': {
        padding: '20px 0px 0px 0px',
      }
    };

    const buttonRed = {
      color: contrast ? '#FFF000' : '#fff',
      backgroundColor: '#E51D21',
      border: '2px solid #E51D21',
      borderRadius: '4px',
      fontWeight: '400',
      fontSize: '14px',
      textTransform: 'none',
      fontFamily: 'Open Sans, sans-serif',
      textAlign: 'left',
      lineHeight: '136.18%',

      "&.MuiButtonBase-root:hover": {
        backgroundColor: '#E51D21',
      }
    };

    const buttonWhite = {
      color: contrast ? '#FFF000' : '#E51D21',
      border: '2px solid #E51D21',
      borderRadius: '4px',
      fontWeight: '400',
      fontSize: '14px',
      textTransform: 'none',
      fontFamily: 'Open Sans, sans-serif',
      textAlign: 'left',
      lineHeight: '136.18%'
    };

    const title = {
      color: contrast ? '#FFF000' : '#000',
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '136.18%',
      alignItems: 'center',
      paddingLeft: '50px',
      paddingTop: '30px',

      '@media (max-width: 599px)': {
        paddingLeft: '40px',
      },
    }

    const text = {
      fontSize: '14px',
      fontWeight: '400',
      textAlign: 'center',
      padding: '15px 20px 2px 20px',
    }

    const buttonContainer = {
      display: 'flex',
      columnGap: 5,
      paddingLeft: 10,
      paddingTop: '15px', 
    }

    function deletePub() {
      const dataForm = new FormData();
      fetch(`${links.url}api/v1/${props.type}/${props.id}`, {
        method: 'DELETE',
        body: dataForm,
        headers: {
          'Authorization':  `Bearer ${localStorage.getItem('token')}`
        },
      }).then(response => {
        if (response.status === 200) {
          setDeleted(true);
          handleDeleteMode(true);
        }
      }).catch(error => {
        // Handle any errors that occur during the promise
        setDeleteError(true);
        console.error(error);
      });
    }


    return (
      <div>
        <Button
          onClick={event => {
            handleOpen();
          }}   

          sx={button}
        >
          <img src={deleteIcon} />
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <button className='close' onClick={handleClose}>
              <img src={closeIcon} alt="fechar" />
            </button>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={title}>
              {props.name ?
                <span>Excluir rascunho salvo? </span> 
                :
                <span>Excluir publicação salva? </span>                     
              } 
            </Typography>
            <Grid sx={text}>
              <p sx={text}>
              Tem certeza que deseja excluir essa consulta? A ação não poderá ser desfeita.
              </p>
            </Grid>
            <Grid sx={buttonContainer}>
            <Button onClick={handleClose} sx={buttonWhite}>
              Cancelar
            </Button>
            <Button onClick={event => {deletePub(props.id); handleClose()}} sx={buttonRed}>
                Excluir
            </Button>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  }

  export default DeleteModal;