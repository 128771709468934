import React, { useRef, useState, useEffect, useContext } from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select, InputBase } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import '../css/IncludePublication.scss'
import links from '../../env';
import UserContext from '../../Store/User_Context';
import FormControlComponent from '../FormControl/FormControlComponent';
import DropdownComponent from '../Dropdowns/DropdownComponent';
import EditedModeContext from '../../Store/EditedMode';

import closeIcon from '../images/Size=Small, Type=fechar.svg'
import highCloseIcon from '../images/Size=Small, Type=fecharContrast.svg'

function EditPublication(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [text, setText] = useState(props.text);
  const [title, setTitle] = useState("");
  const [authors, setAuthors] = useState("");
  const [organization, setOrganization] = useState("");
  const [year, setYear] = useState("");
  const [link, setLink] = useState("");
  const [highlight, setHighlight] = useState(false);
  const [draft, setDraft] = useState(false);
  const [fileName, setFileName] = useState("");
  const [newFilter, setNewFilter] = useState("");
  const [newText, setNewText] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [newAuthors, setNewAuthors] = useState("");
  const [newOrganization, setNewOrganization] = useState("");
  const [newYear, setNewYear] = useState("");
  const [newLink, setNewLink] = useState("");
  const [newHighlight, setNewHighlight] = useState("");
  const [newDraft, setNewDraft] = useState("");
  const [newFileName, setNewFileName] = useState("");
  const [volume, setVolume] = useState("");
  const [newVolume, setNewVolume] = useState("");
  const [page, setPage] = useState("");
  const [newPage, setNewPage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const editedContext = useContext(EditedModeContext);
  const [edited, setEdited] = useState("");
  const changeEditedMode = editedContext.changeEditedMode;

  useEffect(() => {
    setEdited(editedContext.edited)
  }, [editedContext]);

  // Component's style //

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 624,
    heigth: 810,
    bgcolor: contrast ? 'black' : 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    paddingBottom: 8,
    border: contrast ? '1px solid #fff' : '',

    '@media (max-width: 599px)': {
      width: 300,
      height: 800,
    }
  };

  const button = {
    color: 'rgba(23, 43, 64, 1)',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    textAlign: 'left',
    lineHeight: '136.18%',
    minWidth: 20,
    padding: '0 0 0 0',

    '@media (max-width: 599px)': {
      padding: '20px 0px 0px 0px',
    }
  };

  const titleStyle = {
    color: contrast ? '#FFFF00' : '#27486C',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '136.18%',
    alignItems: 'center',
    paddingLeft: '210px',
    paddingTop: '50px',
    paddingBottom: '50px',

    '@media (max-width: 599px)': {
      paddingLeft: '50px',
    }
  }

  // Dropdown values //

  const publicationOptions = [
    { value: "Artigo", label: "Artigo" },
    { value: "Tese", label: "Tese" },
    { value: "Dissertação", label: "Dissertação" },
    { value: "Relatório", label: "Relatório" },
    { value: "Periódico", label: "Periódico" },
  ];

  const textOptions = [
    { value: "Baixar", label: "Baixar" },
    { value: "Acessar", label: "Acessar" },
  ]

  // File upload code //

  const filesElement = useRef(null);

  const sendFileName = (event) => {
    const file = event.target.files[0];
    setNewFileName(file.name);
  };

  // Build JSON code //

  //let dataJson = {}

  function sendPublication() {

    let dataJson = {
      "categoria": newFilter,
      "title": newTitle,
      "autores": newAuthors,
      "organizacao": newOrganization,
      "ano": newYear,
      "texto": newText,
      "upload": newFileName,
      "link": newLink,
      "homepage": newHighlight,
      "rascunho": draft,
      "id": props.id,
      "volume": newVolume,
      "pagina": page,
    };


    const dataForm = new FormData();
    if (text == "Baixar") {
      for (const file of filesElement.current.files) {
        dataForm.append('file', file);
      }
    }
    dataJson.rascunho = false;
    dataForm.append('data', JSON.stringify(dataJson));
    const rp = fetch(`${links.url}api/v1/publication/edit`, {
      method: 'POST',
      body: dataForm,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      if (response.status === 200) {
        setEdited(true);
        changeEditedMode();
      }
    });

    setHighlight(false);
  }

  function sendDraft() {

    let dataJson = {
      "categoria": newFilter,
      "title": newTitle,
      "autores": newAuthors,
      "organizacao": newOrganization,
      "ano": newYear,
      "texto": newText,
      "upload": newFileName,
      "link": newLink,
      "homepage": newHighlight,
      "rascunho": draft,
      "id": props.id,
      "volume": newVolume,
      "pagina": page,
    };

    setDraft(true);

    const dataForm = new FormData();
    if (text == "Baixar") {
      for (const file of filesElement.current.files) {
        dataForm.append('file', file);
      }
    }
    dataJson.rascunho = true;
    dataForm.append('data', JSON.stringify(dataJson));

    const rp = fetch(`${links.url}api/v1/publication/${props.id}`, {
      method: 'POST',
      body: dataForm,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }).then(response => {
      if (response.status === 200) {
        setEdited(true);
        changeEditedMode();
      }
    });

    setHighlight(false);
  }

  useEffect(() => {
    fetch(`${links.url}api/v1/publication/${props.id}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      setTitle(data.result.title);
      setAuthors(data.result.authors);
      setOrganization(data.result.organization);
      setYear(data.result.year);
      setText(data.result.text);
      setFilter(data.result.filter);
      setLink(data.result.link);
      setNewFileName(data.result.Filename);
      setVolume(data.result.volume);
      setPage(data.result.page);
    }).catch(error => {
      // Handle any errors that occur during the promise
      //console.error(error);
    });

  }, [open]);

  return (
    <div>
      <Button onClick={handleOpen} sx={button}>
        <img src={props.image} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close-button' onClick={handleClose}>
            <img src={contrast ? highCloseIcon : closeIcon} alt="fechar" />
          </button>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={titleStyle}>
            {props.action} publicação
          </Typography>

          <div>
            <DropdownComponent
              text={"Selecione a categoria da publicação"}
              filds={publicationOptions}
              value={filter}
              setText={setNewFilter}
            />

            <FormControlComponent
              name={"Título"}
              value={title}
              setText={setNewTitle}
            />

            <FormControlComponent
              name={"Autores/as da publicação"}
              value={authors}
              setText={setNewAuthors}
            />
            <FormControlComponent
              name={"Organização publicada (informar edição se houver)"}
              value={organization}
              setText={setNewOrganization}
            />

            <FormControlComponent
              name={"Volume (v. xx)"}
              value={volume}
              setText={setNewVolume}
            />

            <FormControlComponent
              name={"Paginação (p. xx - xx)"}
              value={page}
              setText={setNewPage}
            />

            <FormControlComponent
              name={"Ano de publicação"}
              value={year}
              setText={setNewYear}
            />

            <DropdownComponent
              text={"Selecione o texto do botão"}
              filds={textOptions}
              value={text}
              setText={setText}
              setNewText={setNewText}
            />

            {text === "Acessar" ?
              <FormControlComponent name={"Link"} value={link} setText={setNewLink} />
              :
              text === "Baixar" ?
                <div className='upload'>
                  <label htmlFor="arquivo" className={`search-button ${contrast ? 'high-search-button' : ''}`}>Procurar arquivo</label>
                  <input type="file" multiple ref={filesElement} name="arquivo" id='arquivo' className='input-button' onChange={sendFileName} />
                  <p className={`file-name ${contrast ? 'high-file-name' : ''}`}>{newFileName}</p>
                </div>
                :
                " "
            }
            <div className={`check ${contrast ? "high-check" : ""}`}>
              <input
                type="checkbox"
                id="homepage"
                name="home"
                value="homepage"
                className={contrast ? 'high-checkbox' : 'checkbox'}
              />
              <span>Colocar a publicação em destaque na homepage</span>
            </div>
          </div>
          <div className={`buttons ${contrast ? "high-buttons" : ""}`}>
            <button className='save'
              onClick={event => {
                sendDraft();
                handleClose();
              }}
            >
              Salvar rascunho
            </button>
            <button className='post'
              onClick={event => {
                sendPublication();
                handleClose();
              }}
            >
              Postar
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default EditPublication;