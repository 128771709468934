import React, { useState, useContext, useEffect } from "react";
import { Grid } from '@mui/material';

import './css/CardLabel.scss'
import UserContext from '../Store/User_Context';
import links from "../env";

import downloadIcon from './images/Size=Small, Type=download.svg'
import downloadIconContrast from './images/Size=Small, Type=download contrast.svg'
import accessIcon from './images/Size=Small, Type=link externo.svg'
import accessIconContrast from './images/Size=Small, Type=link externo contrast.svg'

function CardLabel(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);
  const [color, setColor] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAuthors] = useState("");
  const [organization, setOrganization] = useState("");
  const [page, setPage] = useState("");
  const [volume, setVolume] = useState("");
  const [year, setYear] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  useEffect(() => {
      fetch(`${links.url}api/v1/publication/${props.id}`, {
        method: 'GET',
      }).then(response => {
        //console.log(response)
        return response.json();
      }).then(data => {
        setType(data.result.filter);
        setTitle(data.result.title);
        setAuthors(data.result.authors);
        setOrganization(data.result.organization);
        setYear(data.result.year);
        setText(data.result.text);
        setLink(data.result.link);
        setVolume(data.result.volume);
        setPage(data.result.page);
      }).catch(error => {
        // Handle any errors that occur during the promise
        //console.error(error);
      });
  }, [props.id]);

  useEffect(() => {
    switch (type) {
      case 'Artigo':
        setColor('pink');
        break;
      case 'Tese':
        setColor('green');
        break;
      case 'Relatório':
        setColor('yellow');
        break;
      case 'Dissertação':
        setColor('blue');
        break;
      case 'Periódico':
        setColor('lightblue');
        break;
      case 'Entrevista':
        setColor('gray');
        break;
    }
  });

  useEffect(() => {
    switch (text) {
      case 'Acessar':
        setImage(userContext.contrast ? accessIconContrast : accessIcon);
        setAlt('Link externo');
        break;
      case 'Upload':
        setImage(userContext.contrast ? downloadIconContrast : downloadIcon);
        setAlt('Baixar')
        break;
    }
  })

  return (
    <Grid className={`box-container ${userContext.contrast ? "high-box-container" : ""}`}>
      <div className='individual-box'>
        <h1 className={`border-title ${color}`}>{type}</h1>
        <p className='abstract'>
          {title}
        </p>

        <div className="informations">
          <p className='author'>{author}</p>
          <div className="date-section">
            <h4 className='institution'>{organization}</h4>
            <h4 className='institution'>{volume}</h4>
            <h4 className='institution'>{page}</h4>
            <h4 className='circle' />
            <h4 className='date'>{year}</h4>
          </div>
        </div>
        <a href={link} target="_blank" className="link">
          <button className="option-button">
            <img src={image} alt={alt} />
            {text}
          </button>
        </a>
      </div>
    </Grid>
  )
}

export default CardLabel;