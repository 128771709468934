import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import links from '../../env.js';
import '../css/QuemSomosComponent.scss';
import UserContext from '../../Store/User_Context.js';

import linkexterno from '../images/link externo.svg';
import linkexternocontrast from '../images/link externo contraste.svg'
import analise from '../images/Type=Análise.svg';
import financiamento from '../images/Type=Financiamento.svg';
import oferta from '../images/Type=Oferta.svg';
import trabalho from '../images/Type=Trabalho.svg';
import lattes from '../images/Logo=Lattes, Size=Small, Color=Color.svg';
import arrowIconLeft from '../images/Size=Small, Type=flecha back contrast green.svg'
import arrowIconLeftContrast from '../images/Size=Small, Type=flecha back contrast yellow.svg'

function QuemSomosComponent() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
    <Grid container className={`team-body ${userContext.contrast ? "high-team-body" : ""}`}>
      <Grid className="content-container">
        <Grid className='link-container'>
          <a href={links["home"]}>
            <img className='back-arrow' src={contrast ? arrowIconLeftContrast : arrowIconLeft} alt='voltar' />
          </a>
          <Link to={links["home"]} className="link home-link">Homepage /</Link>
          <Link to={links["quemsomos"]} className="link_atual"> Quem Somos</Link>
        </Grid>

        <Grid className="descricao">
          <div className="descricao-text">
            <h1 className='title'>Quem somos</h1>
            <p>O Laboratório de Dados Educacionais (LDE) é um grupo de pesquisa, interdisciplinar e interinstitucional, que reúne professores, técnicos e discentes do Departamento de Planejamento e Administração Escolar da Universidade Federal do Paraná (DEPLAE/UFPR) e da Faculdade de Administração, Ciências Contábeis e Ciências Econômicas da Universidade Federal de Goiás (FACE/UFG).</p>
            <p> Grupo de pesquisa cadastrado no diretório do Cnpq: </p>
            <img src={userContext.contrast ? linkexternocontrast : linkexterno} alt="link externo" />
            <a href='https://dgp.cnpq.br/dgp/espelhogrupo/2428054493085415'>Acesse</a>
          </div>
        </Grid>
        <Grid className="linhas-pesquisa">
          <h1 className='title'>Linhas de Pesquisa</h1>
          <Grid className="linhas-pesquisa-imgs">
            <figure>
              <img src={analise} alt="Análise" />
              <figcaption>Análise de dados e indicadores educacionais</figcaption>
            </figure>
            <figure>
              <img src={oferta} alt="Oferta" />
              <figcaption>Condições de oferta de ensino</figcaption>
            </figure>
            <figure>
              <img src={trabalho} alt="Condicoes de trabalho" />
              <figcaption>Condições de trabalho docente</figcaption>
            </figure>
            <figure>
              <img src={financiamento} alt="Financiamento de cursos" />
              <figcaption>Financiamento da educação</figcaption>
            </figure>
          </Grid>
        </Grid>
        <Grid className="coordenation">
          <h1 className='title'>Coordenação</h1>
          <Grid className='coordenation-titles'>
            <Grid className="coord-title">
              <h2 className='coordenator-name'>Profª Drª Adriana Dragone Silveira</h2>
              <p className='coordenator-post'>DEPLAE /UFPR
                <a class="test" href="http://lattes.cnpq.br/8297889011471333">
                  <img src={lattes} class="test" alt="Lattes" />
                </a>
              </p>
            </Grid>
            <Grid className="coord-title">
              <h2 className='coordenator-name'>Profª Drª Gabriela Schneider</h2>
              <p className='coordenator-post'>DEPLAE /UFPR
                <a href="http://lattes.cnpq.br/7221139835915668">
                  <img src={lattes} alt="Lattes" />
                </a>
              </p>
            </Grid>
            <Grid className="coord-title">
              <h2 className='coordenator-name'>Prof. Dr. Thiago Alves</h2>
              <p className='coordenator-post'>FACE/UFG
                <a href="http://lattes.cnpq.br/7802091709644694">
                  <img src={lattes} alt="Lattes" />
                </a>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid className='researchers'>
          <h1 className='title'>Pesquisadores</h1>
          <Grid className='researchers-title'>
            <h2 className='researcher-name'>Andrea Polena</h2>
            <h2 className='researcher-name'>Jaqueline Aparecida Cardoso</h2>
          </Grid>
        </Grid>
        <Grid className='discentes'>
          <h1 className='title'>Discentes</h1>
          <Grid className='discentes-titles'>
            <Grid>
              <h2 className='discente-name'>Alessandra Biscaia de Andrade</h2>
              <p className='discente-formation'>Doutoranda UFPR</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Kátia Cristina Sommer Schmidt</h2>
              <p className='discente-formation'>Doutoranda UFPR</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Aline de Barros Vidal Gonçalves</h2>
              <p className='discente-formation'>Doutoranda UFPR</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Neal Dorelis</h2>
              <p className='discente-formation'>Mestrando UFG</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Ana Paula dos Santos Pereira Coelho</h2>
              <p className='discente-formation'>Doutoranda UFPR</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Raphael Demostenes Cardozo </h2>
              <p className='discente-formation'>Doutorando UFPR</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Dhaiene de Jesus dos Reis Bruno</h2>
              <p className='discente-formation'>Doutoranda UFPR</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Rubens Rodrigues Fernandes Junior </h2>
              <p className='discente-formation'>Doutorando UFG</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Fabiana Pereira Salgado Carvalho</h2>
              <p className='discente-formation'>Mestranda UFG</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Teomar Manduca Aires Leal </h2>
              <p className='discente-formation'>Doutorando UFG</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>João Paulo Marra Dantas</h2>
              <p className='discente-formation'>Doutorando UFG</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Weber Tavares da Silva Júnior</h2>
              <p className='discente-formation'>Doutorando UFG</p>
            </Grid>
            <Grid>
              <h2 className='discente-name'>Joelma de Souza Arbigaus</h2>
              <p className='discente-formation'>Doutoranda UFPR</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid className='collaborators'>
          <h1 className='title'>Colaboradores</h1>
          <Grid className='collaborators-titles'>
            <h2 className='collab-name'>Rogério Lopes Craveiros</h2>
            <h2 className='collab-name'>Maíra Gallotti Frantz</h2>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default QuemSomosComponent;

