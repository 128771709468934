import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import '../css/SobreComponent.scss'
import UserContext from '../../Store/User_Context.js';
import links from '../../env';
import CardLabel from '../CardLabel';
import PostedModeContext from '../../Store/PostedContext.js'
import EditModeContext from '../../Store/EditedMode.js'
import DeleteModeContext from "../../Store/DeleteMode.js"

import largeImage from "../images/Size=Large, Page=homepage, Number=7.svg"
import smallImage from "../images/Size=Small, Page=homepage, Number=7.svg"
import icon1 from "../images/Type=Análise.svg"
import icon2 from "../images/Type=Oferta.svg"
import icon3 from "../images/Type=Trabalho.svg"
import icon4 from "../images/Type=Financiamento.svg"
import logoPlataforma from '../images/Size=Medium, Brand=PDE, Type=original, Signature=sem.svg'
import logoPlataformaConstrast from '../images/Size=Medium, Brand=PDE, Type=original 2, Signature=sem.svg'
import logoSimcaq from '../images/Size=Medium, Brand=simcaq, Type=original, Signature=sem.svg'
import logoMapFor from '../images/Size=Medium, Brand=mapfor, Type=original, Signature=sem.svg'
import logoMapForContrast from '../images/Size=Medium, Brand=mapfor, Type=pb, Signature=sem.svg'
import logoMapForPR from '../images/Size=Medium, Brand=mapforPR, Type=original, Signature=sem.svg'
import logoMapForPRContraste from '../images/Size=Medium, Brand=mapforPR, Type=pb, Signature=sem.svg'
import icon5 from "../images/Type=Disciplina.svg"
import icon6 from "../images/Type=Oficinas.svg"
import icon7 from "../images/Type=Pesquisas.svg"
import arrowIconRight from '../images/Size=Small, Type=flecha next.svg'
import arrowIconRightContrast from '../images/Size=Small, Type=flecha next contrast.svg'
import arrowIconLeft from '../images/Size=Small, Type=flecha back contrast green.svg'
import arrowIconLeftContrast from '../images/Size=Small, Type=flecha back contrast yellow.svg'


function ChooseSize(windowSize) {

  if (windowSize[0] <= 1200) {
    console.log(windowSize[0])
    return smallImage;
  }
  return largeImage;
}

function SobreComponent() {

  const userContext = useContext(UserContext);
  const editModeContext = useContext(EditModeContext);
  const [contrast, setContrast] = useState(false);
  const [editmode, setEditmode] = useState(false);
  const [posted, setPosted] = useState(false);
  const postedContext = useContext(PostedModeContext);
  const deleteContext = useContext(DeleteModeContext);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  useEffect(() => {
    setEditmode(editModeContext.editmode)
  }, [editModeContext]);

  useEffect(() => {
    setPosted(postedContext.posted);
  }, [postedContext])

  useEffect(() => {
    setDeleted(deleteContext.deleted)
    console.log(deleteContext)
  }, [deleteContext]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch(`${links.url}api/v1/publication/homepage?page=${1}&pageSize=${3}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      //console.log(data);
      setPosts(data.data);
      //console.log(data.totalCount);
    }).catch(error => {
      // Handle any errors that occur during the promise
      //console.error(error);
    });

  }, [posted, deleted]);


  return (
    <Grid className={`sobre-container ${userContext.contrast ? "high-sobre-container" : ""}`} >
      <div className='route'>
        <a href={links["home"]}>
          <img className='back-arrow' src={contrast ? arrowIconLeftContrast : arrowIconLeft} alt='voltar' />
        </a>
        <a className='home-link' href={links["home"]}>Homepage /</a>
        <h1>O que fazemos</h1>
      </div>
      <Grid className='central-container'>
        <div className='title'>
          <h1> O que fazemos </h1>
          <p>Realizamos pesquisa e formação em política educacional e gestão da educação da
            educação pública. Desenvolvemos soluções computacionais que envolvem organização,
            tratamento, disponibilização e análise de dados e indicadores educacionais, para
            subsidiar o trabalho de gestores públicos e demais atores da sociedade interessados
            na formação, implementação, monitoramento e avaliação de políticas públicas com
            vistas à garantia de uma educação pública, laica, gratuita e de qualidade para
            todos e todas.</p>
        </div>
        <img src={ChooseSize(windowSize)} alt="o que fazemos" />
      </Grid>
      <div className='search-lines'>Linhas de pesquisa</div>
      <Grid className='search-container'>
        <div className='search-box'>
          <img src={icon1} alt="Análise" />
          <p>Análise de dados e indicadores educacionais</p>
        </div>
        <div className='search-box'>
          <img src={icon2} alt="Oferta" />
          <p>Condições da oferta de ensino</p>
        </div>
        <div className='search-box'>
          <img src={icon3} alt="Trabalho" />
          <p>Condições de trabalho docente</p>
        </div>
        <div className='search-box'>
          <img src={icon4} alt="Financiamento" />
          <p>Financiamento da educação</p>
        </div>
      </Grid>
      <div className='projects'>Projetos financiados</div>
      <Grid className='projects-container'>
        <p>Termo de Execução Descentralizada (TED) firmado entre a UFPR e a Secretaria de
          Articulação com os Sistemas de Ensino do Ministério da Educação (SASE/MEC), para
          o desenvolvimento do SimCAQ. Outubro/2015 a outubro/2019.
        </p>
        <p>Financiamento da Pró-Reitoria de Graduação da UFPR para o desenvolvimento da plataforma
          Mapeamento da Formação de Professores do Paraná. Novembro de 2019 a abril de 2021.
        </p>
        <p>
          Termo de Execução Descentralizada (TED) firmado entre a UFPR e o Ministério da Educação,
          para o aprimoramento do SimCAQ. Dezembro/2021 a dezembro/2022.
        </p>
        <p>
          Emenda parlamentar n. 40110010, sob a responsabilidade do Ministério da Educação, da
          senadora Gleise Hoffmann para desenvolvimento da Plataforma de Mapeamento da Formação
          de Professores - MAPFOR BRASIL. Agosto de 2021 a dezembro de 2022.
        </p>
        <p>
          Convênio firmado entre a UFG e Secretaria Municipal de Vitória /ES para o desenvolvimento
          de ferramenta computacional para subsidiar a elaboração de cenários de valores do C
          usto-Aluno Qualidade (CAQ) para cada escola pública municipal de Vitória/ES.
          Outubro de 2020 a 2023.
        </p>
        <p>
          Emenda parlamentar n. 40600004 (2022), sob a responsabilidade do Ministério da Educação,
          do deputado federal Luizão Goulart para atualização de atividades do Laboratório de Dados
          Educacionais. Agosto de 2022 a fevereiro de 2024.
        </p>
      </Grid>
      <div className='solutions'>Soluções computacionais desenvolvidas</div>
      <Grid className={`solutions-box ${userContext.contrast ? "high-solutions-box" : ""}`}>
        <div className='plataform-box'>
          <img src={userContext.contrast ? logoPlataformaConstrast : logoPlataforma} alt='Plataforma de dados educacionais' />
          <p>Consulte os dados da EducaçãoBásica e do Ensino Superior
            em série histórica, com diferentes níveis de desagregação.
          </p>

          <a className='plataforms-button dados-edu' href={links["plataformaDadosEdu"]} target="_blank">Acesse a plataforma</a>
        </div>
        <div className='plataform-box'>
          <img src={logoSimcaq} alt='Simcaq' />
          <p>Calcule o custo da oferta de ensino em condições de qualidade nas escolas
            públicas de educação básica, e faça o planejamento orçamentário.
          </p>
          <a className='plataforms-button simcaq' href={links["simcaq"]} target="_blank">Acesse a plataforma</a>
        </div>
        <div className='plataform-box'>
          <img src={userContext.contrast ? logoMapForContrast : logoMapFor} alt='MapFor' />
          <p>Consulte o painel de indicadores e mapas com informações sobre
            a formação dos professores da educação básica.
          </p>

          <a className='plataforms-button mapfor' href={links["mapfor"]} target="_blank">Acesse a plataforma</a>
        </div>
        <div className='plataform-box mapforbr'>
          <img src={userContext.contrast ? logoMapForPRContraste : logoMapForPR} alt="Mapfor Paraná" />
          <p>
            Consulte o painel de indicadores e
            mapas com informações sobre a formação dos professores da educação básica.
          </p>

          <a className='plataforms-button mapfor' href={links["mapforPR"]} target="_blank">Acesse a plataforma</a>
        </div>
      </Grid>
      <div className='formations'>Formações</div>
      <Grid className='formations-container'>
        <div className='search-box'>
          <img src={icon5} alt="Análise" />
          <p>Oferta de disciplina</p>
        </div>
        <div className='search-box'>
          <img src={icon6} alt="Oferta" />
          <p>Oficinas e minicursos</p>
        </div>
        <div className='search-box'>
          <img src={icon7} alt="Trabalho" />
          <p>Orientações às pesquisas</p>
        </div>
      </Grid>
      <div className='publications'>Publicações</div>
      <Grid className='publications-container'>
        {posts.map(post => (
          <CardLabel id={post.id} accessType={post.text} />
        ))}
      </Grid>

      <div className='more-publications'>
        <a href={links["publicacoes"]} className='show-more'>
          Mais publicações
          <img src={userContext.contrast ? arrowIconRightContrast : arrowIconRight} alt="Mais publicações" />
        </a>
      </div>
    </Grid>
  )

}

export default SobreComponent;