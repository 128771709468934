import React, { useContext, useEffect, useState } from "react";
import { Grid } from '@mui/material'

import '../css/TeamComponent.scss'
import links from '../../env';
import UserContext from '../../Store/User_Context';

import logoC3SL from '../images/Logo=C3SL, Size=Large, Color=Color.svg'
import highC3SL from '../images/Logo=C3SL, Size=Large, Color=White.svg'
import logoGoverno from '../images/MinisterioDaEducacao.svg'
import highGoverno from '../images/HighMinisterio.svg'
import logoPrefeitura from '../images/Logo=Vitoria, Size=Large, Color=Color.svg'
import highPrefeitura from '../images/Logo=Vitoria, Size=Large, Color=White.svg'
import logoFineduca from '../images/Logo=Fineduca, Size=Large, Color=Color.svg'
import highFineduca from '../images/Logo=Fineduca, Size=Large, Color=White.svg'
import logoFineesp from '../images/Logo=Fineesp, Size=Large, Color=Color.svg'
import arrowIconLeft from '../images/Size=Small, Type=flecha back contrast green.svg'
import arrowIconLeftContrast from '../images/Size=Small, Type=flecha back contrast yellow.svg'

function TeamComponent() {
  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
    <Grid className={`team-container ${userContext.contrast ? "high-team-container" : ""}`}>
      <div className="route">
        <a href={links["home"]}>
          <img className='back-arrow' src={contrast ? arrowIconLeftContrast : arrowIconLeft} alt='voltar' />
        </a>
        <a className="home-link" href={links["home"]}>Homepage /  </a>
        <h1>Nossos Parceiros</h1>
      </div>
      <div className="title">
        <h1> Nossos parceiros </h1>
      </div>
      <Grid className="team-box">
        <div className="box1">
          <a href={links["c3sl"]} >
            <img src={contrast ? highC3SL : logoC3SL} alt="C3SL" />
          </a>
          <a href={links["mec"]} >
            <img src={contrast ? highGoverno : logoGoverno} alt="Ministério da Educação - Governo Federal" />
          </a>
          <a href={links["prefeituraDeVitoria"]} >
            <img src={contrast ? highPrefeitura : logoPrefeitura} alt="Prefeitura de Vitória" />
          </a>
        </div>
        <div className="box2">
          <a href={links["fineduca"]} >
            <img src={contrast ? highFineduca : logoFineduca} alt="Fineduca" />
          </a>
          <a href={links["fineesp"]} >
            <img src={logoFineesp} alt="Fineesp" />
          </a>
        </div>
      </Grid>
    </Grid>
  )

}

export default TeamComponent;
