import React, { useState, useEffect, useContext } from 'react';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';

import './css/LoginModal.scss'
import EditmodeContext from '../Store/EditionMode';
import UserContext from '../Store/User_Context';
import LoginFormControl from './FormControl/LoginFormControl';
import { CLIENT_ID } from '../env';
import { CLIENT_SECRET } from '../env';
import links from '../env';
import ResetPasswordModal from './ResetPasswordModal';

import closeIcon from './images/Size=Small, Type=fechar.svg'
import enterIcon from './images/Size=Small, Type=entrar.svg'
import enterIconContrast from './images/Size=Small, Type=entrarContrast.svg'
import closedEyeIcon from './images/Size=Small, Type=olho fechado.svg'
import closedEyeIconContrast from './images/Size=Small, Type=olho fechadoContrast.svg'
import openEyeIcon from './images/Size_Small__Type_olho.svg'
import openEyeIconContrast from './images/Size_Small__Type_olhoContrast.svg'
import PostedModeContext from '../Store/PostedContext';
import DeleteModeContext from '../Store/DeleteMode';

function LoginModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const [posted, setPosted] = useState(false);
  const postedContext = useContext(PostedModeContext)
  let handlePostedMode = postedContext.changePostedMode;

  useEffect(() => {
    setPosted(postedContext.posted);
  }, [postedContext]);

  const [deleted, setDeleted] = useState(false);
  const deletedContext = useContext(DeleteModeContext);
  let handleDeletedMode = deletedContext.changeDeleteMode;

  useEffect(() => {
    setDeleted(deletedContext.deleted);
  }, [deletedContext]);

  const [editmode, setEditmode] = useState("");
  const editmodeContext = useContext(EditmodeContext);
  let handleEditmode = editmodeContext.changeEditmode;

  const [email, setEmail] = useState(localStorage.getItem("remember") != null ? localStorage.getItem("email") : "");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(localStorage.getItem("remember") ? localStorage.getItem("remember") : false);
  const [type, setType] = useState("password");

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeRemember = (event) => {
    if (remember === true)
      setRemember(false);
    else if (localStorage.getItem("remember") === false)
      setRemember(false);
    else
      setRemember(event.target.checked);
  };

  function changeVisibility() {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    heigth: 450,
    bgcolor: contrast ? '#000' : 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    border: contrast ? '1px solid #fff' : 'none',
    paddingBottom: 8,

    '@media (max-width: 599px)': {
      width: 250,
      height: 380,
    },
  };

  const button = {
    color: contrast ? '#FFF000' : 'rgba(23, 43, 64, 1)',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    textAlign: 'left',
    lineHeight: '136.18%',
    paddingLeft: 0,
  };

  const title = {
    color: contrast ? '#FFF000' : '#31AEC4',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '136.18%',
    alignItems: 'center',
    paddingLeft: '90px',
    paddingTop: '30px',

    '@media (max-width: 599px)': {
      paddingLeft: '40px',
    },
  }

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

  const dataJson = {
    "client_id": CLIENT_ID,
    "client_secret": CLIENT_SECRET,
    "email": email,
    "grant_type": "password",
    "password": password,
    "username": email,
  }

  const [invalidLogin, setInvalidLogin] = useState(false);
  const [notAdm, setNotAdm] = useState(false);

  const handleChangeInvalidLogin = (event) => {
    setInvalidLogin(true);
  }

  const handleChangeValidLogin = (event) => {
    setInvalidLogin(false);
  }

  const handleChangeNotAdm = (props) => {
    setNotAdm(props);
  }

  function rememberMe(username, remember) {
    if (remember) {
      localStorage.setItem("email", username);
    }
    else {
      localStorage.setItem("email", "");
    }
    localStorage.setItem("remember", remember);
  }

  function verifyInformations() {
    if (localStorage.getItem("email") != null) {
      setEmail(localStorage.getItem("email"));
    }
  }

  function sendData() {
    rememberMe(email, remember);
    handlePostedMode(false);
    handleDeletedMode(false);
    
    const data = JSON.stringify(dataJson);
    fetch(`${links.url}api/v1//auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data,
    }).then(response => {
      if (response.status === 403) {
        // Authentication failed, handle the error here
        handleChangeInvalidLogin();
        handleEditmode(false);
        throw new Error('Usuario e/ou senha errados');
      }
      if (response.status === 401) {
        handleEditmode(false);
        throw new Error('Erro na requisição')
      }
      if (response.status === 200) {
        handleChangeValidLogin();
        console.log('Usuario e senha válido')
        return response.json();
      }
      if (response.status === 400) {
        handleEditmode(false);
        throw new Error('Não colocou usuário ou/e senha')
      }
    }).then(data => {
      if (!data.admin) {
        handleChangeNotAdm(true);
        handleEditmode(false);
        throw new Error('Usuario não tem permissao de ADMIN');
      }
      else {
        handleChangeNotAdm(false);
        handleEditmode(true);
        sessionStorage.setItem("posted", false);
        sessionStorage.setItem("deleted", false);
        localStorage.setItem('token', data.access_token);
        handleClose();
        console.log("Abrir edição")
      }
    }).catch(error => {
      // Handle any errors that occur during the promise
      console.error(error);
    });
  }

  function logout() {
    window.confirm("Deseja mesmo sair?");
    localStorage.removeItem('token');
    handleEditmode(false);
  }

  document.onkeydown = function (e) {
    if (e.which === 13)
      sendData();
  }

  return (
    <div>
      {editmodeContext.editmode === 'ativado' ?
        <Button onClick={logout} sx={button}>
          Sair
        </Button>
        :
        <Button
          onClick={event => {
            handleOpen();
            // verifyInformations();
          }}
          sx={button}
        >
          Área do administrador
        </Button>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close' onClick={handleClose}>
            <img src={closeIcon} alt="fechar" />
          </button>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={title}>
            Login área do administrador
          </Typography>
          <div className='login-container'>
            <LoginFormControl
              name={"E-mail"}
              value={email}
              handleChange={handleChangeEmail}
              type={"string"}
            />
            <div className='login'>
              <div className='keyword'>
                <LoginFormControl
                  id="password"
                  name={"Senha"}
                  value={password}
                  handleChange={handleChangePassword}
                  type={type}
                />
                <button className='visibility-button' onClick={changeVisibility}>
                  <img src={type === 'password' ? contrast ? openEyeIconContrast : openEyeIcon : type === 'text' ? contrast ? closedEyeIconContrast : closedEyeIcon : ''} />
                </button>
              </div>
            </div>

            {invalidLogin ?
              <div className='error'>
                Usuário ou/e senha inválidos!
              </div>
              :
              notAdm ?
                <div className='error'>
                  Você não possui acesso de administrador!
                </div>
                :
                ''
            }
            <div>
              <Checkbox {...label} defaultChecked={localStorage.getItem('remember') === 'true' ? true : false}
                sx={{
                  color: contrast ? '#FFF000' : '#31AEC4',
                  '&.Mui-checked': {
                    color: contrast ? "#FFF000" : '#31AEC4',
                  }
                }}
                onClick={handleChangeRemember}
              />
              <span className={`remember ${contrast ? 'high-remember' : ''}`}>Lembrar Usuário</span>
            </div>
          </div>
          <div className={`enter-options ${contrast ? 'high-enter-options' : ''}`}>
            <ResetPasswordModal email={email} />
            <button className='enter-button' onClick={sendData}>
              <img src={contrast ? enterIconContrast : enterIcon} alt="entrar" />
              Entrar
            </button>

          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default LoginModal;