import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './Store/User_Context';
import { EditmodeContextProvider } from './Store/EditionMode';
import { PostedModeContextProvider } from './Store/PostedContext';
import { DeleteModeContextProvider } from './Store/DeleteMode';
import { EditedModeContextProvider } from './Store/EditedMode';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <EditmodeContextProvider>
        <UserContextProvider>
          <PostedModeContextProvider>
            <DeleteModeContextProvider>
              <EditedModeContextProvider>
                <App />
              </EditedModeContextProvider>
            </DeleteModeContextProvider>
          </PostedModeContextProvider>
        </UserContextProvider>
      </EditmodeContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
