import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import UserContext from '../Store/User_Context';

import closeIcon from './images/Size=Small, Type=fechar.svg'

function WarningModal() {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    heigth: 450,
    bgcolor: contrast ? '#000' : 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    border: contrast ? '1px solid #fff' : 'none',

    '@media (max-width: 599px)': {
      width: 250,
      height: 430,
    },
  };

  const title = {
    color: contrast ? '#FFF000' : '#31AEC4',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '136.18%',
    alignItems: 'center',
    paddingTop: '40px',
  }

  const text = {
    paddingTop: "10px",
  }

  const button = {
    color: contrast ? '#FFF' : '#fff',
    backgroundColor: contrast ? "#FFF000" : '#31AEC4',
    borderRadius: '4px',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    textAlign: 'left',
    lineHeight: '136.18%',
  };

  function dontShow () {
    localStorage.setItem("incompatibility", "false");
    handleClose();
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close' onClick={handleClose}>
            <img src={closeIcon} alt="fechar" />
          </button>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={title}>
            Incompatibilidade com o navegador Firefox
          </Typography>
          <Grid sx={text}>
            <p>Infelizmente identificamos questões importantes de acessiblidade relacionadas ao navegador Firefox.</p>
            <p>As funções de aumentar e diminuir a fonte não funcionarão corretamente neste navegador.</p>
            <p>Recomendamos fortemente que o sistema LDE seja utilizado em outro navegador, como Microsoft Edge, Google Chrome ou Safari.</p>
          </Grid>
          <Grid sx={{display: "flex", justifyContent: "center", gap: '30px', paddingTop: '20px'}}>
            <Button onClick={handleClose} sx={button}>
              Fechar
            </Button>
            <Button onClick={dontShow} sx={button}>
              Não mostrar novamente
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default WarningModal;