import { useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";

import "./css/FooterComponent.scss"
import links from "../env.js"
import UserContext from '../Store/User_Context';
import LoginModal from "./LoginModal";

import ldeLogo from "./images/logo LDE.svg"
import ldeLogoContrast from "./images/logoLDEContraste.svg"
import ufprLogo from "./images/logo_UFPR.svg"
import ufprLogoContrast from "./images/Logo=UFPR, Size=Large, Type=Mono negativa.svg"
import ufgLogo from "./images/Marca_UFG_cor_completa_horizontal .svg"
import ufgLogoContrast from "./images/Logo=UFG, Size=Large, Type=PB positiva.svg"
import c3slLogo from "./images/logo_C3SL.svg"
import c3slLogoContrast from "./images/Logo=C3SL, Size=Large, Type=PB positiva.svg"

function FooterComponent(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);
  //const [editmode, setEditmode] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
    <Grid container className={`footer-container ${userContext.contrast ? "high-footer-container" : ""}`}>
        <Grid container className="options-container">
          <a href={links["home"]} className="link">Página inicial</a>
          <a href={links["oQueFazemos"]} className="link">O que fazemos</a>
          <a href={links["publicacoes"]} className="link">Publicações</a>
          <a href={links["quemSomos"]} className="link">Quem somos</a>
          <a href={links["nossosParceiros"]} className="link">Nossos parceiros</a>
          <a href={links["contato"]} className="link">Contato</a>
          <LoginModal />
        </Grid>
        <Grid container className="footer-banner">
          <Grid className="footer-logos">
            <a href="http://www.ufpr.br/portalufpr/" target="_blank" rel="noopener noreferrer">
              <img src={userContext.contrast ? ufprLogoContrast : ufprLogo} alt="UFPR" />
            </a>
            <a href="https://www.ufg.br/" target="_blank" rel="noopener noreferrer">
              <img src={userContext.contrast ? ufgLogoContrast : ufgLogo} alt="UFG" />
            </a>
          </Grid>
          <Grid className="realization-container">
            <p>realização</p>
            <a href={links["home"]} target="_blank" rel="noopener noreferrer">
              <img src={userContext.contrast ? ldeLogoContrast : ldeLogo} alt="LDE" />
            </a>
            <a href="https://www.c3sl.ufpr.br/" target="_blank" rel="noopener noreferrer">
              <img src={userContext.contrast ? c3slLogoContrast : c3slLogo} alt="C3SL" />
            </a>
          </Grid>
        </Grid>
    </Grid>
  )
}

export default FooterComponent;