import { useEffect, useState, createContext } from "react";

const DeleteModeContext = createContext({
  deleted: "",
  changeDeleteMode: () => { },
});

export function DeleteModeContextProvider(props) {
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    const getDeleteMode = sessionStorage.getItem("deleted");
    if (getDeleteMode === "false")
      setDeleted(false);
    else  
      setDeleted(true);
  }, []);

  const handleChangeDeleteMode = (props) => {
    let newDeleted = props;
    sessionStorage.setItem("deleted", newDeleted);
    setDeleted(newDeleted);
  };

  const context = {
    deleted: deleted,
    changeDeleteMode: handleChangeDeleteMode,
  };

  return (
    <DeleteModeContext.Provider value={context}>
      {props.children}
    </DeleteModeContext.Provider>
  );
}

export default DeleteModeContext;
