import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import links from "./env.js"

import HomePageComponent from './components/Pages/HomeComponent';
import NotFoundComponent from './components/Pages/NotFoundComponent';
import FooterComponent from './components/FooterComponent';
import SocialFooter from "./components/SocialComponent";
import HeaderComponent from './components/HeaderComponent';
import QuemSomosComponent from './components/Pages/QuemSomosComponent';
import TeamComponent from './components/Pages/TeamComponent';
import AcessibilityComponent from './components/Pages/AcessibilityComponent';
import SobreComponent from './components/Pages/SobreComponent';
import MapaComponent from './components/Pages/MapaComponent';
import PublicationComponent from './components/Pages/PublicationsPage';
import ContatoComponent from './components/Pages/ContactComponent';
import UsersTable from './components/Pages/UsersTable';

function LDE() {
  return (
    <Router>
      <div className="App opensans">
        <HeaderComponent />
        <Routes>
          <Route path={links["home"]} element={<HomePageComponent />} />
          <Route path={links["quemSomos"]} element={<QuemSomosComponent />} />
          <Route path={links["nossosParceiros"]} element={<TeamComponent />} />
          <Route path={links["acessibilidade"]} element={<AcessibilityComponent />} />
          <Route path={links["oQueFazemos"]} element={<SobreComponent />} />
          <Route path={links["mapaDoSite"]} element={<MapaComponent />} />
          <Route path={links["publicacoes"]} element={<PublicationComponent />} />
          <Route path={links["contato"]} element={<ContatoComponent />} />
          <Route path={links["usuarios"]} element={<UsersTable />} />
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
        <FooterComponent />
        <SocialFooter />
      </div>
    </Router>
  );
}

export default LDE;
