import React, { useState, useEffect, useContext } from 'react';
import { InputLabel, FormControl, InputBase } from "@mui/material";
import { styled } from '@mui/material/styles';
import UserContext from '../../Store/User_Context';
import TextField from '@mui/material/TextField';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  border: '1px solid #525252',
  borderRadius: '4px',
  padding: '8px 16px',
}));


function FormControlComponent(props) {
  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);
  const [information, setInformation] = useState(props.value);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast);
  }, [userContext]);

  const handleChangeInformation = (event) => {
    setInformation(event.target.value);
    props.setText(event.target.value);
    const inputValue = event.target.value;
    setIsEmpty(inputValue === '');
  }

  return (
    <FormControl
      error={props.valid}
      sx={{
        m: 1,
        minWidth: 600,

        '@media (max-width: 599px)': {
          minWidth: 300,
        }
      }}
    >
      <InputLabel
        id="demo-simple-select-standard-label"
        required={props.required}
        sx={{
          color: contrast ? '#FFF' : '#525252',
          fontWeight: 400,
          paddingLeft: 1,
          background: contrast ? 'black' : '#fff',

          "&.Mui-focused": {
            color: contrast ? '#FFF000' : '#27486C',
          },
        }}
      >
        {props.name}
      </InputLabel>
      
      <BootstrapInput
        id="demo-customized-textbox"
        value={information}
        onChange={handleChangeInformation}
        sx={{ color: contrast ? '#fff' : '#000' }}
      />
    </FormControl>
  );
}

export default FormControlComponent;
