import React, { useEffect, useState, useContext } from "react";

import '../css/Posts.scss'
import EditPublication from "./EditPublication";
import DeleteModal from "../DeleteOption";

import UserContext from '../../Store/User_Context';
import DeleteModeContext from '../../Store/DeleteMode';

import editIcon from '../images/Size=Small, Type=editar.svg'
import editIconContrast from '../images/Size=Small, Type=editarContrast.svg'

function ShowDate(windowSize) {

  if (windowSize[0] <= 1279) {
    return false;
  } else
    return true;
}

function ShowType(windowSize) {

  if (windowSize[0] <= 600) {
    return false;
  } else
    return true;
}

function Posts(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const deleteContext = useContext(DeleteModeContext);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setDeleted(deleteContext.deleted)
  }, [deleteContext]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  return (
    <div className="big-container">
      <div className="posts-container">
        <div className="single-container">
          <p className={`fild ${contrast ? 'high-fild' : ''}`}>Nome</p>
          {props.posts.map(post => (
            <div>
              <li key={post} className={`text ${contrast ? 'high-text' : ''}`}>
                {post.title.slice(0, 30)}...
              </li>
            </div>
          ))}
        </div>
        <div className="single-container">
          {ShowType(windowSize) ?
            <div>
              <p className={`fild ${contrast ? 'high-fild' : ''}`}>Tipo</p>
              {props.posts.map(post => (
                <div>
                  {post.is_draft ?
                    <li className={`text ${contrast ? 'high-text' : ''}`}>Rascunho</li>
                    :
                    <li className={`text ${contrast ? 'high-text' : ''}`}>Publicação</li>
                  }
                </div>
              ))}
            </div>
            :
            ""
          }
        </div>
        <div className="single-container">
          {ShowDate(windowSize) ?
            <div>
              <p className={`fild ${contrast ? 'high-fild' : ''}`}>Data de criação</p>
              {props.posts.map(post => (
                <div>
                  <li key={post} className={`text ${contrast ? 'high-text' : ''}`}>
                    {post.created_at}
                  </li>
                </div>
              ))}
            </div>
            :
            " "
          }
        </div>
        <div className="single-container">
          {ShowDate(windowSize) ?
            <div>
              <p className={`fild ${contrast ? 'high-fild' : ''}`}>Data de modificação</p>
              {props.posts.map(post => (
                <div>
                  <li key={post} className={`text ${contrast ? 'high-text' : ''}`}>
                    {post.updated_at}
                  </li>
                </div>
              ))}
            </div>
            :
            " "
          }
        </div>
        <div className="single-container icon-container">
          {props.posts.map(post => (
            <div className="icon-functions">
              <li key={post} className={`text ${contrast ? 'high-text' : ''}`}>
                <EditPublication
                  image={contrast ? editIconContrast : editIcon}
                  action={"Editar"}
                  id={post.id}
                />
              </li>
              <DeleteModal type={"publication"} id={post.id} name={post.is_draft} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Posts;