import React, { useState, useEffect, useContext } from 'react'
import { Grid, Pagination } from "@mui/material";
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';

import UserContext from '../../Store/User_Context'
import links from '../../env';
import '../css/PublicationsPage.scss'
import DropdownFilters from '../Dropdowns/DropdownFilters';
import EditModeContext from '../../Store/EditionMode';
import EditionOptionsPublications from '../PublicationComponents/EditionOptionsPublication';
import DeleteModeContext from '../../Store/DeleteMode';
import PostedModeContext from '../../Store/PostedContext';
import EditedModeContext from '../../Store/EditedMode';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PublicationsGrade from './PublicationsGrade';

function PublicationComponent() {

  const userContext = useContext(UserContext);
  let handleContrast = userContext.changeContrast;
  const [contrast, setContrast] = useState(false);
  const editModeContext = useContext(EditModeContext);
  const [editmode, setEditmode] = useState(false);

  const postedContext = useContext(PostedModeContext);
  const [posted, setPosted] = useState("");

  useEffect(() => {
    setPosted(postedContext.posted)
  }, [postedContext]);

  const editedContext = useContext(EditedModeContext);
  const [edited, setEdited] = useState("");

  useEffect(() => {
    setEdited(editedContext.edited)
  }, [editedContext]);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  useEffect(() => {
    setEditmode(editModeContext.editmode)
  }, [editModeContext]);

  const deleteContext = useContext(DeleteModeContext);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setDeleted(deleteContext.deleted)
    console.log(deleteContext)
  }, [deleteContext]);

  const [filter, setFilter] = useState("all");
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Estado para armazenar a página atual
  const [pageSize, setPageSize] = useState(9);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetch(`${links.url}api/v1/publication?filter=false&page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      //console.log(data.data);
      setPosts(data.data);
      setTotalPages(Math.ceil(data.totalCount / pageSize));
      //console.log(data.totalCount);
    }).catch(error => {
      // Handle any errors that occur during the promise
      //console.error(error);
    });

  }, [currentPage, pageSize, posted, deleted, edited]);


  const handlePageClick = (event, page) => {
    setCurrentPage(page);

    fetch(`${links.url}api/v1/publication?filter=false&page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      setPosts(data.data);
      setTotalPages(Math.ceil(data.totalCount / pageSize));
      console.log(data.data);
    }).catch(error => {
      // Handle any errors that occur during the promise
      console.error(error);
    });
  };

  const filters = [
    { value: "all", label: "Todos" },
    { value: "Artigo", label: "Artigo" },
    { value: "Tese", label: "Tese" },
    { value: "Relatorio", label: "Relatório" },
    { value: "dissertacao", label: "Dissertação" },
    { value: "monografia", label: "Monografia" },
    { value: "periodico", label: "Periódico" },
    { value: "entrevista", label: "Entrevista" },
    { value: "anais", label: "Anais" },
  ];

  function goHeader() {
    window.scrollTo(document.body.scrollHeight, 0)
  }

  const handleChangeCategory = (event) => {
    setFilter(event.target.value);

    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify(event.target.value));
    fetch(`${links.url}api/v1/publication/type?tipo=${event.target.value}&page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      setPosts(data.data)
      setTotalPages(data.totalCount/ pageSize)
    });
  };

  return (
    <Grid className='publications-component'>
      {editModeContext.editmode === "ativado" ?
        <div className='banner' id='editionmode'>
          {goHeader()}
          Você está no modo edição
        </div>
        :
        <div />
      }
      <Grid className={`publications-container ${userContext.contrast ? "high-publications-container" : ""}`}>
        <div className='principal-container'>
          <div className='route'>
            <a className='home-link' href={links["home"]}>Homepage /</a>
            <h1 className='atual-page'>Publicações</h1>
          </div>
          <div className='title-container'>
            <h1>Publicações</h1>
            <div className='filters'>
              <span>Filtrar por </span>
              <DropdownFilters
                filter={filter}
                handleChange={handleChangeCategory}
                filds={filters}
                required={false}
                tam={200}
              />
            </div>
          </div>
          <div className='description'>
            <p>As publicações aqui divulgadas são realizadas no âmbito de cada projeto de pesquisa pelos integrantes da equipe
              (e também em parceria com pesquisadores externos) que subsidiam o desenvolvimento, a manutenção e a evolução dos
              produtos do grupo LDE.
            </p>
          </div>
          {editModeContext.editmode === "ativado" ?
            <div className='edition-options'>
              <EditionOptionsPublications />
            </div>
            :
            <div />}
        </div>

        {totalPages === 0 ?
          <p className='none'>Nenhuma publicação ou rascunho por enquanto...</p>
          :
          <PublicationsGrade posts={posts} />
        }
        <Stack spacing={2} className="pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageClick}
            siblingCount={0}
            size='small'
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                sx={{
                  color: contrast ? '#FFF000' : '#2A95A8',

                  "&.Mui-selected": {
                    color: contrast ? '#000' : 'white',
                    backgroundColor: contrast ? '#FFF000' : '#2A95A8',
                  },

                  "&.Mui-selected:hover": {
                    backgroundColor: contrast ? '#FFF000' : '#2A95A8',
                  }
                }}
              />
            )}
          />
        </Stack>
      </Grid>
    </Grid>
  )

}

export default PublicationComponent;