import { useEffect, useState, createContext } from "react";

const EditedModeContext = createContext({
  edited: "",
  changeEditedMode: () => { },
});

export function EditedModeContextProvider(props) {
  const [edited, setEdited] = useState("");

  useEffect(() => {
    const getEditedMode = sessionStorage.getItem("edited");
    setEdited(getEditedMode | "");
  }, []);

  const handleChangeEditedMode = (props) => {
    let newEdited = edited === true ? false : true;
    sessionStorage.setItem("Editedd", newEdited);
    setEdited(newEdited);
  };

  const context = {
    edited: edited,
    changeEditedMode: handleChangeEditedMode,
  };

  return (
    <EditedModeContext.Provider value={context}>
      {props.children}
    </EditedModeContext.Provider>
  );
}

export default EditedModeContext;
