import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Button, Grid } from '@mui/material';

import '../css/NotFoundComponent.scss'
import links from "../../env.js"
import UserContext from '../../Store/User_Context';

import notFoundLarge from '../images/Size=Large, Page=404, Number=1.svg'
import notFoundSmall from '../images/Size=Small, Page=404, Number=1.svg'
import ArrowIcon from '../images/Size=Small, Type=flecha back.svg'
import ArrowIconContrast from '../images/Size=Small, Type=flecha back contrast.svg'

function ChooseSize(windowSize) {

  if (windowSize[0] <= 599) {
    console.log(windowSize[0])
    return notFoundSmall;
  }
  return notFoundLarge;
}


function NotFoundComponent() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <Grid container className={`not-found-grid ${userContext.contrast ? "high-not-found-grid" : ""}`} >
      <Grid className='not-found-text'>
        <div>
          <p className='text1'>Oops...</p>
          <p className='text2'>404</p>
          <p className='text3'>Parece que essa página não existe</p>
          <a href={links["home"]} target="_self" rel="noopener noreferrer">
            <Button className='return-home-button' sx={{ textTransform: 'none' }}>
              <img src={userContext.contrast ? ArrowIconContrast : ArrowIcon} />
              Voltar à página inicial
            </Button>
          </a>
        </div>
      </Grid>
      <Grid className='not-found-element'>
        <div className='not-found-images'>
          <img width={"100%"} className='teste' src={ChooseSize(windowSize)} alt="Página não encontrada" />
        </div>
      </Grid>
    </Grid>
  );

}

export default NotFoundComponent;