import React, { useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ContactFormControl from "../FormControl/ContactFormControl.js";

import links from "../../env.js";
import "../css/ContatoComponent.scss";
import UserContext from "../../Store/User_Context.js";

import endereco from "../images/Size_Small__Type_localização__2_.svg";
import enderecoContrast from "../images/Size_Small__Type_localização__2_ ContrastWhite.svg"
import email from "../images/Size=Small, Type=avião papel.svg";
import emailContrast from "../images/Size=Small, Type=avião papelContrast.svg";
import redes_sociais from "../images/Size=Small, Type=megafone.svg";
import redes_sociaisContrast from "../images/Size=Small, Type=megafoneContrast.svg";


function ContatoComponent() {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  return (
    <Grid className={`page-container ${contrast ? "high-page-container" : ""}`}>
      <Grid container className="principal-container">     
      <Grid item xs={12} className="link-container">
        <Link to={links["home"]} className="link">
          Homepage / {" "}
        </Link>
        <Link to={links["contato"]} className="link_atual">
          {" "}
          Contato
        </Link>
      </Grid>

      <Grid item xs={12} className="titulo-container">
        <h1 className="titulo">Deixe seu feedback</h1>
        <p className="subtitulo">Nós adoraríamos ouvir você.</p>
      </Grid>

      <Grid item xs={12} className="contato-container">
        <Grid item xs={6} className="inputs">
          <ContactFormControl></ContactFormControl>
        </Grid>

        <Grid item xs={6} className="dados">
          <Grid className="endereco">
            <div className="titulo">
              <img src={contrast ? enderecoContrast : endereco} alt="Endereço" />
              <h1>Endereço</h1>
            </div>
            <div className="linha"></div>
            <p>
              <b>UFPR - Universidade Federal do Paraná</b>
              <br></br>
              Rua Rockefeller, 57 Campus Teixeira Soares - Rebouças, 2º andar,
              Sala 214<br></br>
              Curitiba-PR, CEP: 80230-130
            </p>
            <p>
              <b>UFG - Universidade Federal de Goiás</b>
              <br></br>
              Rua Samambaia, s/n Campus Samambaia - Prédio da Faculdade de
              Administração, Ciências Contáveis e Ciências Econômicas, sala 1110
              <br></br>
              Goiânia-GO, CEP: 74001-970
            </p>
          </Grid>

          <Grid className="email">
            <div className="titulo">
              <img src={contrast ? emailContrast : email} alt="E-mail" />
              <h1>E-mail</h1>
            </div>
            <div className="linha"></div>
            <p>dadoseducacionais@ufpr.br</p>
          </Grid>

          <Grid className="redes-sociais">
            <div className="titulo">
              <img src={contrast ? redes_sociaisContrast : redes_sociais} alt="Redes Sociais" />
              <h1>Redes sociais</h1>
            </div>
            <div className="linha"></div>
            <p>
              Facebook: Laboratório de Dados Educacionais<br></br>
              Youtube: Laboratório de Dados Educacionais<br></br>
              Instagram: @laboratoriodedadoseducacionais
            </p>
          </Grid>
        </Grid>
      </Grid>
      </Grid>
 
    </Grid>
  );
}

export default ContatoComponent;
