import React, { useRef, useState, useEffect, useContext, createContext } from 'react';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';

import '../css/PublicationsComponent.scss'
import links from '../../env';
import UserContext from '../../Store/User_Context';
import DropdownFilters from '../Dropdowns/DropdownFilters';
import EditedModeContext from '../../Store/EditedMode';
import Posts from './Posts';
import DeleteModeContext from '../../Store/DeleteMode';
import PostedModeContext from '../../Store/PostedContext';
import SuccessModal from '../SucessModal';

import closeIcon from '../images/Size=Small, Type=fechar.svg'
import highCloseIcon from '../images/Size=Small, Type=fecharContrast.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function PublicationsComponent(props) {

  const userContext = useContext(UserContext);
  const [contrast, setContrast] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setContrast(userContext.contrast)
  }, [userContext]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const postedContext = useContext(PostedModeContext);
  const [posted, setPosted] = useState(false);

  useEffect(() => {
    setPosted(postedContext.posted)
  }, [postedContext]);

  const editedContext = useContext(EditedModeContext);
  const [edited, setEdited] = useState("");

  useEffect(() => {
    setEdited(editedContext.edited)
  }, [editedContext]);

  const deleteContext = useContext(DeleteModeContext);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setDeleted(deleteContext.deleted)
    console.log(deleteContext)
  }, [deleteContext]);


  useEffect(() => {
    fetch(`${links.url}api/v1/publication?filter=${filter}&order=${order}&page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      //console.log(data);
      setTotalPages(Math.ceil(data.totalCount / pageSize));
      setPosts(data.data);
      //console.log(data.totalCount);
    }).catch(error => {
      // Handle any errors that occur during the promise
      //console.error(error);
    });

  }, [posted, deleted]);

  // Component's style //

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 1200,
    //heigth: 810,
    bgcolor: contrast ? 'black' : 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    paddingBottom: 8,
    border: contrast ? '1px solid #fff' : '',

    '@media (max-width: 599px)': {
      width: 300,
      height: 600,
      top: '50%',
      overflowY: "auto",
    },

    '@media (min-width: 600px) and (max-width: 1200px)': {
      top: '50%',
      width: 700,
      height: 500,
      overflowY: "auto",
    },

    '@media (min-width: 1201px) and (max-width: 1600px)': {
      top: '50%',
      width: 1000,
      height: 500,
      overflowY: "auto",
    },
    
    ' @media (min-width: 1601px)': {
      top: '50%',
      width: 1200,
      heigth: 810,
      overflowY: "auto",
    },
  };

  const button = {
    color: 'rgba(23, 43, 64, 1)',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    textAlign: 'left',
    lineHeight: '136.18%',
    minWidth: 20,
  };

  const titleStyle = {
    color: contrast ? '#FFFF00' : '#27486C',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '136.18%',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingTop: '50px',
    paddingBottom: '50px',

    '@media (max-width: 599px)': {
      paddingLeft: '50px',
    },

    '@media (min-width: 600px) and (max-width: 1200px)': {
      paddingTop: '20px',
    }
  }

  // Pagination functions

  const [currentPage, setCurrentPage] = useState(1); // Estado para armazenar a página atual
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);

  const [posts, setPosts] = useState([]);
  const [order, setOrder] = useState("DESC");
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    fetch(`${links.url}api/v1/publication?filter=${filter}&order=${order}&page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      //console.log(data);
      setTotalPages(Math.ceil(data.totalCount / pageSize));
      setPosts(data.data);
      //console.log(data.totalCount);
    }).catch(error => {
      // Handle any errors that occur during the promise
      //console.error(error);
    });

  }, [currentPage, pageSize, posted, deleted, edited]);

  const visiblePages = 4;
  let startPage, endPage;

  if (totalPages <= visiblePages) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= Math.ceil(visiblePages / 2)) {
    startPage = 1;
    endPage = visiblePages;
  } else if (currentPage + Math.floor(visiblePages / 2) >= totalPages) {
    startPage = totalPages - visiblePages;
    endPage = totalPages;
  } else {
    startPage = currentPage + 1;
    endPage = currentPage + 2;
  }

  const pageNumbers = Array.from({ length: 4 }, (_, i) => startPage + i);

  const handlePageClick = (event, page) => {
    setCurrentPage(page);

    fetch(`${links.url}api/v1/publication?page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      setTotalPages(Math.ceil(data.totalCount / pageSize));
      setPosts(data.data);
    }).catch(error => {
      // Handle any errors that occur during the promise
      console.error(error);
    });
  };

  const orderFilters = [
    { value: "ASC", label: "Mais antigos" },
    { value: "DESC", label: "Mais recentes" },
  ];

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);

    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify(event.target.value));
    fetch(`${links.url}api/v1/publication?filter=${filter}&order=${event.target.value}&page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      setPosts(data.data)
    });
  };

  const filters = [
    { value: "all", label: "Todos" },
    { value: "false", label: "Publicados" },
    { value: "true", label: "Rascunhos" },
  ];

  const handleChangeCategory = (event) => {
    setFilter(event.target.value);

    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify(event.target.value));
    fetch(`${links.url}api/v1/publication?filter=${event.target.value}&order=${order}&page=${currentPage}&pageSize=${pageSize}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(response => {
      //console.log(response)
      return response.json();
    }).then(data => {
      setPosts(data.data)
    });
  };

  return (
    <div>
      <Button onClick={handleOpen} sx={button}>
        <img src={props.image} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className='close-button' onClick={handleClose}>
            <img src={contrast ? highCloseIcon : closeIcon} alt="fechar" />
          </button>
          <div className='informations'>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={titleStyle}>
              Publicações
            </Typography>
            {totalPages === 0 ?
              " "
              :
              <div className='pages-filters'>
                <DropdownFilters
                  text={"Filtros"}
                  filter={filter}
                  handleChange={handleChangeCategory}
                  filds={filters}
                  required={false}
                />
                <DropdownFilters
                  text={"Ordenar por:"}
                  filter={order}
                  handleChange={handleChangeOrder}
                  filds={orderFilters}
                  required={false}
                />
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageClick}
                    siblingCount={0}
                    size='small'
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                        sx={{
                          color: contrast ? '#FFF000' : '#27486C',

                          "&.Mui-selected": {
                            color: contrast ? '#000' : 'white',
                            backgroundColor: contrast ? '#FFF000' : '#27486C',
                          },

                          "&.Mui-selected:hover": {
                            backgroundColor: contrast ? '#FFF000' : '#27486C',
                          }
                        }}
                      />
                    )}
                  />
                </Stack>
              </div>
            }
          </div>

          {totalPages === 0 ?
            <p className='none'>Nenhuma publicação ou rascunho por enquanto...</p>
            :
            <Posts number={currentPage} posts={posts} />
          }

          {deleted ?
            <SuccessModal type={"publicação"} text={"deletada"} />
            :
            ""
          }
        </Box>
      </Modal>
    </div>
  )
}

export default PublicationsComponent;